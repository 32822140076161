import { Box, Typography, Alert } from "@mui/material";

const ForgotPassword = (props) => {
    return (
        <Box sx={props.style}>
            <Typography
                sx={{ textAlign: "center" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
            >
                Please insert your Employee ID
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div id="pass__modal">
                    <input
                        type="text"
                        onChange={(e) => props.setUser(e.target.value)}
                    />
                    <div className="row__modal" id="row__modal">
                        <button
                            className="cancel__btn"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="send__btn"
                            onClick={props.forgotPassword}
                        >
                            Send
                        </button>
                    </div>
                    {props.showModalAlert ? (
                        <Alert
                            severity={props.severity}
                            sx={{ marginTop: "2%" }}
                        >
                            {props.message}
                        </Alert>
                    ) : null}
                </div>
            </Typography>
        </Box>
    );
};

export default ForgotPassword;
