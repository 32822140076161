import "../styles/contact.scss";
import Navigation from "../Navigation";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";

const Contact = () => {
    const iconStyle = {
        borderRight: "1px solid grey",
        padding: "5px 8px",
    };
    return (
        <>
            <Navigation />
            <main className="contact__page">
                <h1>Contact HR</h1>
                <div className="contact__details">
                    <h2>Telephone</h2>
                    <div className="contact__detail">
                        <CallIcon sx={iconStyle} />
                        <span>
                            <a href="tel:01413198451">0141 319 8451</a>
                        </span>
                    </div>
                    <h2>Email</h2>
                    <div className="contact__detail">
                        <EmailIcon sx={iconStyle} />
                        <span>
                            <a href="mailto:hr@racetrackpitstop.co.uk">
                                hr@racetrackpitstop.co.uk
                            </a>
                        </span>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Contact;
