import "../App.scss";
import Logo from "./images/logo.png";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { encryptData } from "../utils/crypto";
import { Modal, Alert } from "@mui/material";
import ForgotPassword from "./modals/ForgotPassword";
import ForgotEmployeeID from "./modals/ForgotEmployeeID";
import { getSites, getRoles } from "../utils/apiUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "75vw",
    },
};

export default function Home() {
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_URL;
    const config = { headers: { "Content-Type": "application/json" } };

    //alerts
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");
    //forgot pass modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [user, setUser] = useState("");
    //forgot employee ID
    const [openID, setOpenID] = useState(false);
    const handleOpenID = () => setOpenID(true);
    const handleCloseID = () => {
        setOpenID(false);
        setShowDetails(false);
        setShowEmailInput(false);
    };
    const [userEmail, setUserEmail] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userSite, setUserSite] = useState("");
    const [userPosition, setUserPosition] = useState("");
    const [sites, setSites] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [type, setType] = useState("password");

    useEffect(() => {
        getRoles()
            .then((res) => {
                setRoles(res);
            })
            .catch((err) => {
                console.log(err);
            });
        getSites()
            .then((res) => {
                setSites(res);
            })
            .catch((err) => {
                console.log(err);
            });

        let preLoaded = sessionStorage.getItem("first-load");
        if (preLoaded == null) {
            sessionStorage.setItem("first-load", true);
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
            console.log("Cache cleared");
        }
    }, []);

    const handleLogin = () => {
        document.getElementById("login_button").style.display = "none";
        if (id === "" || password === "") {
            setMessage("Please insert your EmployeeId and password");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById(
                    "login_button"
                ).style = `background: #fff;
          font-size: 14px;
          margin-top: 30px;
          padding: 16px 20px;
          border-radius: 26px;
          text-transform: uppercase;
          font-weight: 700;
          display: flex;
          align-items: center;
          text-align: center;`;
            }, 3000);
        } else {
            let data = {
                username: id,
                password: encryptData(password, process.env.REACT_APP_EMP_KEY),
            };
            axios
                .post(`${url}/portal/login`, data, config)
                .then((res) => {
                    localStorage.setItem("user", id);
                    sessionStorage.setItem("token", res.data.token);
                    navigate(`/${id}`);
                })
                .catch((err) => {
                    setMessage(`${err.response.data.msg}`);
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById(
                            "login_button"
                        ).style = `background: #fff;
          font-size: 14px;
          margin-top: 30px;
          padding: 16px 20px;
          border-radius: 26px;
          text-transform: uppercase;
          font-weight: 700;
          display: flex;
          align-items: center;
          text-align: center;`;
                    }, 3500);
                });
        }
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleLogin();
        }
    };

    const forgotPassword = () => {
        document.getElementById("row__modal").style.display = "none";
        if (user === "") {
            setMessage("Please insert your EmployeeId");
            setSeverity("warning");
            setShowModalAlert(true);
            setTimeout(() => {
                setShowModalAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("row__modal").style.display = "flex";
            }, 3000);
        } else {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/portal/login/reset/alert`,
                    {
                        username: user,
                    }
                )
                .then((res) => {
                    setMessage(
                        "If your Employee Id was correct you will receive an email with a link to reset your password"
                    );
                    setSeverity("success");
                    setShowModalAlert(true);
                    setTimeout(() => {
                        setShowModalAlert(false);
                        setMessage("");
                        setSeverity("");
                        setPassword("");
                        handleClose();
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                    setMessage("An error occurred, please try again");
                    setSeverity("error");
                    setShowModalAlert(true);
                    setTimeout(() => {
                        setShowModalAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById("row__modal").style.display =
                            "flex";
                    }, 3000);
                });
        }
    };

    const forgotEmployeeID = () => {
        document.getElementById("row__modal__id").style.display = "none";
        if (showDetails) {
            if (
                userFirstName === "" ||
                userLastName === "" ||
                userSite === "" ||
                userSite === "--Please Select--" ||
                userPosition === "" ||
                userPosition === "--Please Select--"
            ) {
                setMessage("Please make sure all details are entered");
                setSeverity("warning");
                setShowModalAlert(true);
                setTimeout(() => {
                    setShowModalAlert(false);
                    setMessage("");
                    setSeverity("");
                    document.getElementById("row__modal__id").style.display =
                        "flex";
                }, 3000);
            } else {
                let data = {
                    firstName: encryptData(
                        userFirstName,
                        process.env.REACT_APP_EMP_KEY
                    ),
                    lastName: encryptData(
                        userLastName,
                        process.env.REACT_APP_EMP_KEY
                    ),
                    company: userSite,
                    position: userPosition,
                };
                axios
                    .post(`${url}/portal/login/reset/username`, data)
                    .then((res) => {
                        setMessage(
                            "If your details are correct you will receive an email with a link to reset your Employee ID"
                        );
                        setSeverity("success");
                        setShowModalAlert(true);
                        setTimeout(() => {
                            setShowModalAlert(false);
                            setMessage("");
                            setSeverity("");
                            setUserFirstName("");
                            setUserLastName("");
                            setUserSite("");
                            setUserPosition("");
                            handleCloseID();
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMessage("An error occurred, please try again");
                        setSeverity("error");
                        setShowModalAlert(true);
                        setTimeout(() => {
                            setShowModalAlert(true);
                            setMessage("");
                            setSeverity("");
                            document.getElementById(
                                "row__modal__id"
                            ).style.display = "flex";
                        }, 3000);
                    });
            }
        } else if (showEmailInput) {
            let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
            if (userEmail === "") {
                setMessage("Please enter your email");
                setSeverity("warning");
                setShowModalAlert(true);
                setTimeout(() => {
                    setShowModalAlert(false);
                    setMessage("");
                    setSeverity("");
                    document.getElementById("row__modal__id").style.display =
                        "flex";
                }, 3000);
            } else if (!userEmail.match(regex)) {
                setMessage("Please check the format of your email");
                setSeverity("warning");
                setShowModalAlert(true);
                setTimeout(() => {
                    setShowModalAlert(false);
                    setMessage("");
                    setSeverity("");
                    document.getElementById("row__modal__id").style.display =
                        "flex";
                }, 3000);
            } else {
                let data = {
                    email: encryptData(
                        userEmail,
                        process.env.REACT_APP_EMP_KEY
                    ),
                };
                axios
                    .post(`${url}/portal/login/reset/username`, data)
                    .then((res) => {
                        setMessage(
                            "If your email is correct you will receive an email with further instructions"
                        );
                        setSeverity("success");
                        setShowModalAlert(true);
                        setTimeout(() => {
                            setShowModalAlert(false);
                            setMessage("");
                            setSeverity("");
                            setUserFirstName("");
                            setUserLastName("");
                            setUserSite("");
                            setUserPosition("");
                            handleCloseID();
                        }, 3000);
                    })
                    .catch((err) => {
                        console.log(err);
                        setMessage("An error occurred, please try again");
                        setSeverity("error");
                        setShowModalAlert(true);
                        setTimeout(() => {
                            setShowModalAlert(true);
                            setMessage("");
                            setSeverity("");
                            document.getElementById(
                                "row__modal__id"
                            ).style.display = "flex";
                        }, 3000);
                    });
            }
        }
    };

    const handleShowEmail = () => {
        setShowEmailInput(!showEmailInput);
        setShowDetails(false);
        setUserFirstName("");
        setUserLastName("");
        setUserSite("");
        setUserPosition("");
    };

    const handleShowDetails = () => {
        setShowDetails(!showDetails);
        setShowEmailInput(false);
        setUserEmail("");
    };

    const togglePass = () => {
        let password = document.getElementById("password_input");
        let type =
            password.getAttribute("type") === "password" ? "text" : "password";
        password.setAttribute("type", type);
        setType(type);
    };

    return (
        <main className="home__page">
            <img src={Logo} alt="logo" />
            <h1>Please Login with your Employee ID</h1>
            <div className="login__field">
                <PersonIcon className="login_icon" />
                <input
                    type="text"
                    placeholder="Employee ID"
                    onChange={(e) => setId(e.target.value)}
                />
            </div>
            <div className="login__field">
                {type === "password" ? (
                    <VisibilityOffIcon
                        className="login_icon"
                        color="primary"
                        onClick={togglePass}
                    />
                ) : (
                    <VisibilityIcon
                        className="login_icon"
                        color="primary"
                        onClick={togglePass}
                    />
                )}
                <input
                    type="password"
                    placeholder="Your password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    id="password_input"
                />
            </div>
            <button
                className="login_button"
                onClick={handleLogin}
                id="login_button"
            >
                LOGIN
            </button>
            <small onClick={handleOpen}>Forgot Password?</small>
            <small onClick={handleOpenID}>Forgot Employee ID?</small>
            {showAlert ? (
                <Alert severity={severity} sx={{ marginTop: "2%" }}>
                    {message}
                </Alert>
            ) : null}

            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ForgotPassword
                    setUser={setUser}
                    handleClose={handleClose}
                    forgotPassword={forgotPassword}
                    message={message}
                    severity={severity}
                    showModalAlert={showModalAlert}
                    style={style}
                />
            </Modal>

            <Modal open={openID}>
                <ForgotEmployeeID
                    sites={sites}
                    roles={roles}
                    showEmailInput={showEmailInput}
                    showDetails={showDetails}
                    handleShowEmail={handleShowEmail}
                    handleShowDetails={handleShowDetails}
                    setUserEmail={setUserEmail}
                    setUserFirstName={setUserFirstName}
                    setUserLastName={setUserLastName}
                    setUserSite={setUserSite}
                    setUserPosition={setUserPosition}
                    handleCloseID={handleCloseID}
                    forgotEmployeeID={forgotEmployeeID}
                    message={message}
                    severity={severity}
                    showModalAlert={showModalAlert}
                    style={style}
                />
            </Modal>
        </main>
    );
}
