import "../styles/user.scss";
import { useParams, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../Navigation";
import moment from "moment";
import { Tooltip, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import StrollerIcon from "@mui/icons-material/Stroller";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getEmployeeDetails } from "../../utils/apiUtils";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { decryptData } from "../../utils/crypto";

export default function UserPage() {
  const url = process.env.REACT_APP_API_URL;
  const { user } = useParams();
  const [employee, setEmployee] = useState("");
  const [holidays, setHolidays] = useState({});
  const [rota, setRota] = useState({});
  const [site, setSite] = useState({});
  const [currentWeek, setCurrentWeek] = useState("");


  document.title = "RaceTrack | Employee";

  // Get user
  useEffect(() => {
    const currentUser = localStorage.getItem("user");
    const token = sessionStorage.getItem("token")
     
    if (currentUser === user){
      getEmployeeDetails(user, token)
      .then((data) => {
        setHolidays(data.holidays);
        setRota(data.rota);
        setSite(data.site);
        setCurrentWeek(data.rota.week);
        let name = decryptData(data.firstName, process.env.REACT_APP_EMP_KEY)
        let surname = decryptData(data.lastName, process.env.REACT_APP_EMP_KEY)
        setEmployee(`${name} ${surname}`)
      })
      .catch((err) => {
        console.log(err);
      });
    } else{
      window.location.replace("/")
    }

  }, []);

  //Rota week pagination
  const handleWeekChange = (direction) => {
    let newWeek = "";
    if (direction === "forward") {
      newWeek = moment(rota.week, "DD/MM/YYYY")
        .add(7, "d")
        .format("YYYY-MM-DD");
    } else if (direction === "back") {
      newWeek = moment(rota.week, "DD/MM/YYYY")
        .subtract(7, "d")
        .format("YYYY-MM-DD");
    }
    let data = {
      username: user,
      start: newWeek,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/rota/user/weekly`, data)
      .then((res) => {
        setRota(res.data);
      })
      .catch((err) => console.log(err));
  };

  return (
    <main className="user__page">
      <Navigation />
      <h1>Welcome {employee}!</h1>
      <div className="user__details">
        <table className="user__table">
          <thead>
            <tr>
              <th className="table_header" colSpan={2}>
                Your Store
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Store</th>
              <td className="cell_right">{site.name}</td>
            </tr>
            <tr>
              <th>Telephone</th>
              <td className="cell_right">{site.phone}</td>
            </tr>
          </tbody>
        </table>
        {/* HOLIDAYS ONLY TO APPEAR ON JANUARY 2024 */}
        {/* <table className="user__table">
          <thead>
            <tr>
              <th className="table_header" colSpan={2}>
                Holiday Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Annual Holiday Entitlement</th>
              <td className="cell_right">{holidays.entitlement}</td>
            </tr>
            <tr>
              <th>Holidays Accrued</th>
              <td className="cell_right">{holidays.accrued}</td>
            </tr>
            <tr>
              <th>Holidays taken</th>
              <td className="cell_right">{holidays.taken}</td>
            </tr>
          </tbody>
        </table> */}
        {/* ROTA SECTION FOR FUTURE VERSION OF HR */}
        {/* <table className="user__table">
          <thead>
            <tr>
              <th colSpan={7} className="table_header">
                Weekly Rota
              </th>
            </tr>
            <tr>
              <IconButton onClick={() => handleWeekChange("back")}>
                <KeyboardArrowLeftIcon className="table__icon" />
              </IconButton>

              <th style={{ textAlign: "center" }} colSpan={3}>
                Week Commening:
              </th>
              <td style={{ textAlign: "center" }} colSpan={2}>
                {moment(rota.week, "DD/MM/YYYY").format("DD/MM/YYYY")}
              </td>
              <IconButton onClick={() => handleWeekChange("forward")}>
                <KeyboardArrowRightIcon className="table__icon" />
              </IconButton>
            </tr>
            {rota && rota.shifts ? (
              <>
                <tr className="day_header">
                  <th>SAT</th>
                  <th>SUN</th>
                  <th>MON</th>
                  <th>TUE</th>
                  <th>WED</th>
                  <th>THU</th>
                  <th>FRI</th>
                </tr>
                <tr className="rota_dates">
                  <td>{moment(rota.shifts[0].date).format("DD/MM")}</td>
                  <td>{moment(rota.shifts[1].date).format("DD/MM")}</td>
                  <td>{moment(rota.shifts[2].date).format("DD/MM")}</td>
                  <td>{moment(rota.shifts[3].date).format("DD/MM")}</td>
                  <td>{moment(rota.shifts[4].date).format("DD/MM")}</td>
                  <td>{moment(rota.shifts[5].date).format("DD/MM")}</td>
                  <td>{moment(rota.shifts[6].date).format("DD/MM")}</td>
                </tr>
                <tr>
                  {rota.shifts.map(function (d, i) {
                    return (
                      <>
                        {d.leaveType ? (
                          <>
                            {d.leaveType == 1 && d.statusId == 2 ? (
                              <td>
                                <Tooltip title="Holiday (Paid)">
                                  <BeachAccessIcon
                                    color="primary"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 2 && d.statusId == 2 ? (
                              <td>
                                <Tooltip title="Holiday (Unpaid)">
                                  <BeachAccessIcon
                                    color="secondary"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 3 ? (
                              <td>
                                <Tooltip title="Sickness">
                                  <VaccinesIcon
                                    color="error"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 4 ? (
                              <td>
                                <Tooltip title="Mat/Pat Leave">
                                  <StrollerIcon
                                    color="secondary"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 5 ? (
                              <td>
                                <Tooltip title="AWOL">
                                  <PsychologyAltIcon
                                    color="warning"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 6 ? (
                              <td>
                                <Tooltip title="Other">
                                  <HelpOutlineIcon
                                    color="info"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : (
                              <td>
                                <Tooltip title="Holidays not approved, speak to your line manager">
                                  <NewReleasesIcon
                                    color="warning"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            )}
                          </>
                        ) : (
                          <td>{d.start}</td>
                        )}
                      </>
                    );
                  })}
                </tr>
                <tr>
                  {rota.shifts.map(function (d, i) {
                    return (
                      <>
                        {d.leaveType ? (
                          <>
                            {d.leaveType == 1 && d.statusId == 2 ? (
                              <td>
                                <Tooltip title="Holiday (Paid)">
                                  <BeachAccessIcon
                                    color="primary"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 2 && d.statusId == 2 ? (
                              <td>
                                <Tooltip title="Holiday (Unpaid)">
                                  <BeachAccessIcon
                                    color="secondary"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 3 ? (
                              <td>
                                <Tooltip title="Sickness">
                                  <VaccinesIcon
                                    color="error"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 4 ? (
                              <td>
                                <Tooltip title="Mat/Pat Leave">
                                  <StrollerIcon
                                    color="secondary"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 5 ? (
                              <td>
                                <Tooltip title="AWOL">
                                  <PsychologyAltIcon
                                    color="warning"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : d.leaveType == 6 ? (
                              <td>
                                <Tooltip title="Other">
                                  <HelpOutlineIcon
                                    color="info"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            ) : (
                              <td>
                                <Tooltip title="Holidays not approved, speak to your line manager">
                                  <NewReleasesIcon
                                    color="warning"
                                    fontSize="large"
                                    className="table_icon"
                                  />
                                </Tooltip>
                              </td>
                            )}
                          </>
                        ) : (
                          <td>{d.end}</td>
                        )}
                      </>
                    );
                  })}
                </tr>
              </>
            ) : null}
            <tr>
              <th style={{ textAlign: "center" }} colSpan={4}>
                Total Weekly hours:
              </th>
              <td style={{ textAlign: "center" }} colSpan={3}>
                {parseFloat(rota.total).toFixed(2)}
              </td>
            </tr>
          </thead>
        </table> */}
      </div>
    </main>
  );
}
