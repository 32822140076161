import "./styles/footer.scss";

const Footer = () => {
    return (
        <footer className="footer">
            <p>
                Designed and Developed by{" "}
                <a href="https://nitrosolutions.co.uk/">Nitro Solutions</a>
            </p>
        </footer>
    );
};

export default Footer;
