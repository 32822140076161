import { Typography } from "@mui/material";
import { useState } from "react";

export default function TillAgreement(props) {

  const [tillNo, setTillNo] = useState(0)
  const [paypointNo, setPaypointNo] = useState(0)

  const update = async (val, type) => {

    var data = { tillNo: tillNo, paypointNo: paypointNo }

    if (type == "till") {
      setTillNo(val)
      data.tillNo = val
    } else {
      setPaypointNo(val)
      data.paypointNo = val
    }
    
    props.updateFormData(data)
  }

  return (
    <main className="staff_management">
      <h1>Paypoint Agreement (Previously Till Agreement)</h1>
      <section className="conversation_form">
        <input type="number" onChange={(e) => update(e.target.value, "till")} />
        <label>
          PayPoint ID given to employee <span className="label">*</span>
        </label>
        <input type="number" onChange={(e) => update(e.target.value, "paypoint")} />
        <Typography variant="h4" sx={{ margin: "2% 0 1% 0" }}>
          Confirmation
        </Typography>
        <Typography variant="body1">
          I confirm that I have been given the above number to access the
          system as part of my employment with RaceTrack. I also confirm I will
          not allow anyone else to use my till and that all recorded
          discrepancies linked with this number will be solely my
          responsibility to resolve as per the Deductions from Pay I have
          previously signed
        </Typography>
      </section>
    </main>
  );
}
