import { useState } from "react"

export default function HigChat(props) {

  const [general,setGeneral] = useState("")
  const [training,setTraining] = useState("")
  const [concerns,setConcerns] = useState("")
  const [other,setOther] = useState("")
  
  const update = async (val, type) => {
    var data = {
      general: general,
      training: training,
      concerns: concerns,
      other: other
    }

    data[type] = val;

    props.updateFormData(data)
  }

  return (
    <main className="staff_management">
      <h1>HIG Chat</h1>
      <section className="conversation_form">
        <label>
          General - How's It Going? What do they enjoy?{" "}
          <span className="label">*</span>
        </label>
        <textarea onChange={(e) => { setGeneral(e.target.value); update(e.target.value, "general") }} />
        <label>
          Training - Where does the employee feel they are at in their training?
          Are there any areas that they feel they need more training? Look for weaknesses in their training, things that they may have not mentioned.{" "}
          <span className="label">*</span>
        </label>
        <textarea onChange={(e) => { setTraining(e.target.value); update(e.target.value, "training") }} />
        <label>
          Concerns? - Do you have any concerns regarding the job? I.e., concerns with shift patterns, bullying, lack of training, pay etc.{" "}
          <span className="label">*</span>
        </label>
        <textarea onChange={(e) => { setConcerns(e.target.value); update(e.target.value, "concerns") }} />
        <label>
          Other - Use this space to cover/record any topics not mentioned in the
          above boxes. <span className="label">*</span>
        </label>
        <textarea onChange={(e) => { setOther(e.target.value); update(e.target.value, "other") }} />
      </section>
    </main>
  );
}
