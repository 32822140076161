import { useParams } from "react-router-dom";
import { decryptData } from "../../utils/crypto";
import { useEffect, useState } from "react";
import "../styles/form.scss";
import axios from "axios";
import moment from "moment";
import Loader from "../animations/Loader";
import Alert from "@mui/material/Alert";
import Tick from "../animations/Tick";
import { Button } from "@mui/material";
import Questions from "./forms/Questions.json";
import TextArea from "./forms/TextArea";
import EaseToStore from "./forms/EaseToStore";
import SelectYesNo from "./forms/SelectYesNo";
import SelectSuccess from "./forms/SelectSuccess";
import Logo from "../images/logo.png";

export default function InterviewQuestions() {
  const { id } = useParams();
  const key = process.env.REACT_APP_NS_ENC;
  const config = { headers: { "Content-Type": "application/json" } };
  const today = new Date();
  const [interviewData, setInterviewData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [success, setSuccess] = useState(null);
  const [dbSuccess, setDbSuccess] = useState(null);
  const [dbReason, setDbReason] = useState("");
  const [dbStartDate, setDbStartDate] = useState("");
  const [dbContractedHours, setDbContractedHours] = useState("");
  const [contractHours, setContractHours] = useState("");
  const [startDate, setStartDate] = useState("");
  const [reason, setReason] = useState("");
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showTick, setShowTick] = useState(false);
  const [format, setFormat] = useState("");
  const [questionsType, setQuestionsType] = useState("")
  const [interviewQs, setInterviewQs] = useState([])

  useEffect(() => {
    //gets interview details
    let preLoaded = sessionStorage.getItem("first-load");
    if (preLoaded == null) {
      sessionStorage.setItem("first-load", true);
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      console.log("Cache cleared");
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/interview/${id}`, config)
      .then(async (res) => {

        let qs = []
        if (res.data.roleId == 3 || res.data.roleId == 4 || res.data.roleId == 5 || res.data.roleId == 7 || res.data.roleId == 8 || res.data.roleId == 11 || res.data.roleId == 12 || res.data.roleId == 15) {
          qs = Questions.csa
          setQuestionsType("CSA")
        } else if (res.data.roleId == 10) {
          qs = Questions.Manager
          setQuestionsType("Manager")
        } else if (res.data.roleId == 18 || res.data.roleId == 19) {
          qs = Questions.Tubbees_Manager
          setQuestionsType("Tubbees Manager")
        } else {
          qs = Questions.supervisor;
          setQuestionsType("Supervisor")
        }

        setDbSuccess(res.data.success)
        setDbReason(res.data.rejectionReason)
        setDbStartDate(res.data.startDate)
        setDbContractedHours(res.data.contract)

        //decripts the PID's
        let questions = "";
        let note;
        if (res.data.questions != null) {
          questions = JSON.parse(res.data.questions);
          for (let i = 0; i < questions.length; i++) {
            if (questions[i].answer) {
              qs[i].answer = questions[i].answer
            }
          }
        }

        setInterviewQs(qs)

        if (res.data.cv.split(".").pop() === "pdf") {
          setFormat("pdf");
        } else {
          setFormat("doc");
        }
        if (res.data.note) {
          note = res.data.note;
        } else {
          note = null;
        }

        var fullName = "";
        if (!res.data.fullName || res.data.fullName == null) {
          var firstName = decryptData(res.data.firstName, key)
          var lastName = decryptData(res.data.lastName, key)
          fullName = `${firstName} ${lastName}`
        } else {
          fullName = decryptData(res.data.fullName, key)
        }
        const decryptionData = {
          fullName: fullName,
          email: decryptData(res.data.email, key),
          date: moment(res.data.date).format("YYYY-MM-DD HH:mm"),
          role: res.data.roleName,
          cv: res.data.cv,
          cvBuffer: res.data.cvBuffer,
          roleId: res.data.roleId,
          questions: qs,
          note: note,
          companyName: res.data.companyName,
        };
        setInterviewData(decryptionData);
        setShowLoader(false);

      })
      .catch((err) => {
        console.log(err);
        setMessage("Unable to load interview. Please try again later.");
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
        }, 5000);
      });
  }, []);

  const handleQuestions = (qIdx, answer) => {
    interviewQs[qIdx].answer = answer;
  };

  const handleSuccess = (val) => {
    if (val === "Yes") {
      setSuccess(true);
    } else if (val === "No") {
      setSuccess(false);
    } else {
      setSuccess(null);
    }
  };

  const handleNoSuccess = (why) => {
    console.log(why)
    setReason(why);
  };

  const handleDoc = () => {
    let windowName = `${interviewData.firstName}_${interviewData.lastName}_cv`;
    let pdfWindow = window.open("", windowName);
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64, " +
      encodeURI(interviewData.cvBuffer) +
      "'></iframe>"
    );
  };

  const handlePdf = (base64) => {
    const binary = window.atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(new ArrayBuffer(len));
    for (let i = 0; i < len; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return new Blob([bytes], { type: "application/pdf" });
  };

  const viewPdf = async () => {
    // console.log(interviewData.cvBuffer)
    const pdf = await handlePdf(interviewData.cvBuffer);
    let pdfUrl = URL.createObjectURL(pdf);
    window.open(pdfUrl);
  };

  const handleStart = (date) => {
    if (today > new Date(date)) {
      document.getElementById("interview_btn").style.display = "none";
      setMessage("Start date cannot be in the past");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("start_date").value = today;
        document.getElementById("interview_btn").style.display = "flex";
      }, 3000);
    } else {
      setStartDate(date);
    }
  };

  const handleContract = (contract) => {
    if (parseInt(contract) < 0 || parseInt(contract) > 45) {
      document.getElementById("interview_btn").style.display = "none";
      setMessage("Contracted hours cannot be less than 0 or over 45");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("contract_hours").value = 0;
        document.getElementById("interview_btn").style.display = "flex";
      }, 3000);
    } else if (contract.includes(".")) {
      document.getElementById("interview_btn").style.display = "none";
      setMessage("Contracted hours are rounded numbers");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("contract_hours").value = 0;
        document.getElementById("interview_btn").style.display = "flex";
      }, 3000);
    } else {
      setContractHours(contract);
    }
  };

  const handleSubmit = async (subType) => {

    document.getElementById("interview_btn").style.display = "none";
    var submitInterview = false;
    var interviewResult = {}

    if (subType == "saved") {
      interviewResult = {
        interviewId: id,
        success: success,
        questions: interviewQs,
        user: "Staff Portal"
      };
      submitInterview = true
    } else {
      var answeredQs = interviewQs.filter((i) => i.answer)
      if (
        (interviewData.roleId === 4 ||
          interviewData.roleId === 5 ||
          interviewData.roleId === 3 ||
          interviewData.roleId === 7 ||
          interviewData.roleId === 8 ||
          interviewData.roleId === 15 ||
          interviewData.roleId === 11 ||
          interviewData.roleId === 12) &&
        answeredQs.length != 16
      ) {
        setMessage("You must answer all questions");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else if (
        (interviewData.roleId === 6 ||
          interviewData.roleId === 9 ||
          interviewData.roleId === 13 ||
          interviewData.roleId === 14 ||
          interviewData.roleId === 16 ||
          interviewData.roleId === 20) &&
        answeredQs.length != 13
      ) {
        setMessage("You must answer all questions");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else if (interviewData.roleId === 10 && answeredQs.length != 17) {
        setMessage("You must answer all questions");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else if (interviewData.roleId === 18 && answeredQs.length != 19) {
        setMessage("You must answer all questions");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else if (success && (startDate === "" || contractHours === "")) {
        setMessage("Please ensure you fill start date and contracted hours");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else if (success === false && reason === "") {
        setMessage("Please give a reason for not being successful");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else if (success === null) {
        setMessage("Please confirm whether or not the candidate is successful");
        setSeverity("warning");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      } else {
        if (success === false) {
          interviewResult = {
            interviewId: id,
            success: success,
            questions: interviewQs,
            reason: reason,
            user: "Staff Portal"
          };
        } else {
          interviewResult = {
            interviewId: id,
            success: success,
            questions: interviewQs,
            contract: contractHours,
            startDate: startDate,
            user: "Staff Portal"
          };
        }
        submitInterview = true
      }
    }

    if (submitInterview) {
      console.log(`Interview submission has been ${subType}`)
      setShowTick(true);
      setTimeout(() => {
        setShowTick(false);
        document.getElementById("interview_btn").style.display = "flex";
        window.close();
      }, 3000);
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/interview/complete`,
          interviewResult,
          config
        )
        .then((res) => {
          console.log(res.status);
          if (res.status === 200) {
            setShowTick(true);
            setTimeout(() => {
              setShowTick(false);
              document.getElementById("interview_btn").style.display = "flex";
              window.close();
            }, 3000);
          } else {
            setMessage("Something went wrong");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setMessage("");
              setSeverity("");
              document.getElementById("interview_btn").style.display = "flex";
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          setMessage("Something went wrong");
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            setSeverity("");
            document.getElementById("interview_btn").style.display = "flex";
          }, 3000);
        });
    }
  };

  const handleNowShow = () => {
    document.getElementById("interview_btn").style.display = "none";

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/interview/no-show`,
        { applicantId: id, user: "Staff Portal" },
        config
      )
      .then((res) => {
        console.log(res.data);
        setMessage("Applicant marked as no show for HR");
        setSeverity("success");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
          window.close();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setMessage("Something went wrong");
        setSeverity("error");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setMessage("");
          setSeverity("");
          document.getElementById("interview_btn").style.display = "flex";
        }, 3000);
      });
  };

  return (
    <main>
      <section className="form__main">
        <img src={Logo} alt="logo" />
        <h1 className="title">Interview Questionnaire</h1>
        {showLoader ? (
          <Loader />
        ) : (
          <>
            <label className="main_label">
              Applicant Name:{" "}
              <span>{`${interviewData.fullName}`}</span>
            </label>
            <label className="main_label">
              Email: <span>{interviewData.email}</span>
            </label>
            <label className="main_label">
              Company: <span>{interviewData.companyName}</span>
            </label>
            <label className="main_label">
              Role: <span>{interviewData.role}</span>
            </label>
            {interviewData.note ? (
              <label className="main_label">
                Note from HR: <span>{interviewData.note}</span>
              </label>
            ) : null}
            <a
              onClick={format === "pdf" ? viewPdf : handleDoc}
              target="_blank"
              className="interview_btn"
            >
              OPEN CV
            </a>

            {questionsType == "CSA" ?
              <>
                {interviewQs.map(function (q, idx) {
                  let output;
                  if (idx == 1) {
                    output = (
                      <EaseToStore
                        handleQuestions={handleQuestions}
                        answer={q.answer}
                        dbSuccess={dbSuccess}
                        question={q.question}
                        idx={idx}
                      />
                    );
                  } else if (idx == 7 || idx == 11) {
                    output = (
                      <SelectYesNo
                        handleQuestions={handleQuestions}
                        answer={q.answer}
                        dbSuccess={dbSuccess}
                        question={q.question}
                        idx={idx}
                      />
                    );
                  } else if (idx === interviewQs.length - 1) {
                    output = (
                      <SelectSuccess
                        handleSuccess={handleSuccess}
                        success={success}
                        dbSuccess={dbSuccess}
                        handleStart={handleStart}
                        handleContract={handleContract}
                        handleNoSuccess={handleNoSuccess}
                      />
                    );
                  } else {
                    output = (
                      <TextArea
                        handleQuestions={handleQuestions}
                        answer={q.answer}
                        dbSuccess={dbSuccess}
                        question={q.question}
                        idx={idx}
                      />
                    );
                  }
                  return (
                    <form className="interview_form">
                      <label>{q.question}</label>
                      <>{output}</>
                    </form>
                  );
                })}
              </>
              : questionsType == "Manager" ?
                <>
                  {interviewQs.map(function (q, idx) {
                    let output;
                    if (idx == 4 || idx == 9) {
                      output = (
                        <SelectYesNo
                          handleQuestions={handleQuestions}
                          question={q.question}
                          idx={idx}
                          dbSuccess={dbSuccess}
                        />
                      );
                    } else if (idx === interviewQs.length - 1) {
                      output = (
                        <SelectSuccess
                          handleSuccess={handleSuccess}
                          success={success}
                          dbSuccess={dbSuccess}
                          handleStart={handleStart}
                          handleContract={handleContract}
                          handleNoSuccess={handleNoSuccess}
                        />
                      );
                    } else {
                      output = (
                        <TextArea
                          handleQuestions={handleQuestions}
                          question={q.question}
                          idx={idx}
                          dbSuccess={dbSuccess}
                        />
                      );
                    }
                    return (
                      <form className="interview_form">
                        <label>{q.question}</label>
                        <>{output}</>
                      </form>
                    );
                  })}
                </>
                : questionsType == "Tubbees Manager" ?
                  <>
                    {interviewQs.map(function (q, idx) {
                      let output;
                      if (idx == 5) {
                        output = (
                          <SelectYesNo
                            dbSuccess={dbSuccess}
                            handleQuestions={handleQuestions}
                            question={q.question}
                            idx={idx}
                          />
                        );
                      } else if (idx === interviewQs.length - 1) {
                        output = (
                          <SelectSuccess
                            handleSuccess={handleSuccess}
                            success={success}
                            dbSuccess={dbSuccess}
                            handleStart={handleStart}
                            handleContract={handleContract}
                            handleNoSuccess={handleNoSuccess}
                          />
                        );
                      } else {
                        output = (
                          <TextArea
                            handleQuestions={handleQuestions}
                            question={q.question}
                            idx={idx}
                            dbSuccess={dbSuccess}
                          />
                        );
                      }
                      return (
                        <form className="interview_form">
                          <label>{q.question}</label>
                          <>{output}</>
                        </form>
                      );
                    })}
                  </>
                  :
                  <>
                    {interviewQs.map(function (q, idx) {
                      let output;
                      if (idx == 4 || idx == 10) {
                        output = (
                          <SelectYesNo
                            handleQuestions={handleQuestions}
                            question={q.question}
                            idx={idx}
                            dbSuccess={dbSuccess}
                          />
                        );
                      } else if (idx === interviewQs.length - 1) {
                        output = (
                          <SelectSuccess
                            handleSuccess={handleSuccess}
                            success={success}
                            dbSuccess={dbSuccess}
                            handleStart={handleStart}
                            handleContract={handleContract}
                            handleNoSuccess={handleNoSuccess}
                          />
                        );
                      } else {
                        output = (
                          <TextArea
                            handleQuestions={handleQuestions}
                            question={q.question}
                            idx={idx}
                            dbSuccess={dbSuccess}
                          />
                        );
                      }
                      return (
                        <form className="interview_form">
                          <label>{q.question}</label>
                          <>{output}</>
                        </form>
                      );
                    })}
                  </>
            }

            {dbSuccess == null ?
              <div className="save_btn_row" id="interview_btn">
                {success == null ? (
                  <Button
                    variant="contained" color="warning" sx={{ alignSelf: "center" }} onClick={() => handleSubmit("saved")} >Save
                  </Button>
                ) : (null)}
                <Button
                  variant="contained" color="success" sx={{ alignSelf: "center" }} onClick={() => handleSubmit("confirmed")} >Submit
                </Button>
                <Button
                  variant="outlined" color="error" onClick={handleNowShow} >No Show
                </Button>
              </div>
              : dbSuccess == true ?
                <p style={{ position: "relative", left: "-420px" }}>Successful - {dbContractedHours} hour contract offered and start date is {moment(dbStartDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</p>
                :
                <p style={{ position: "relative", left: "-475px" }}>Unsuccessful - {dbReason}</p>
            }



            {showAlert ? (
              <Alert
                severity={severity}
                sx={{ marginTop: "1%", alignSelf: "center" }}
              >
                {message}
              </Alert>
            ) : null}
            {showTick ? <Tick /> : null}
          </>
        )}
      </section>
    </main>
  );
}
