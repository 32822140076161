import { Box, Typography, Alert, Button } from "@mui/material";

const ForgotEmployeeID = (props) => {
    return (
        <Box sx={props.style}>
            <Typography
                sx={{ textAlign: "center" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
            >
                Forgot Employee ID
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div id="pass__modal">
                    <div>
                        <Button
                            sx={{ width: "100%", marginBottom: "15px" }}
                            variant={
                                props.showEmailInput ? "outlined" : "contained"
                            }
                            onClick={props.handleShowEmail}
                        >
                            Retrieve with email
                        </Button>
                        <Button
                            sx={{ width: "100%", marginBottom: "15px" }}
                            variant={
                                props.showDetails ? "outlined" : "contained"
                            }
                            onClick={props.handleShowDetails}
                        >
                            Retrieve with personal details
                        </Button>
                    </div>
                    {props.showDetails ? (
                        <>
                            <div className="form__field">
                                <label>What is you first name?</label>
                                <input
                                    type="text"
                                    placeholder="First name"
                                    onChange={(e) =>
                                        props.setUserFirstName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form__field">
                                <label>What is your last name?</label>
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={(e) =>
                                        props.setUserLastName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form__field">
                                <label>What store do you work at?</label>
                                <select
                                    onChange={(e) =>
                                        props.setUserSite(e.target.value)
                                    }
                                >
                                    <option>--Please Select--</option>
                                    {props.sites
                                        ? props.sites.map((site, i) => {
                                              return (
                                                  <option key={i}>
                                                      {site.name}
                                                  </option>
                                              );
                                          })
                                        : null}
                                </select>
                            </div>
                            <div className="form__field">
                                <label>What is your role?</label>
                                <select
                                    onChange={(e) =>
                                        props.setUserPosition(e.target.value)
                                    }
                                >
                                    <option>--Please Select--</option>
                                    {props.roles
                                        ? props.roles.map((role, i) => {
                                              return (
                                                  <option key={i}>
                                                      {role.roleName}
                                                  </option>
                                              );
                                          })
                                        : null}
                                </select>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {props.showEmailInput ? (
                        <div className="form__field">
                            <label>What is your email?</label>
                            <input
                                type="email"
                                placeholder="Email"
                                onChange={(e) =>
                                    props.setUserEmail(e.target.value)
                                }
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className="row__modal" id="row__modal__id">
                        <button
                            className="cancel__btn"
                            onClick={props.handleCloseID}
                        >
                            Cancel
                        </button>
                        {props.showDetails || props.showEmailInput ? (
                            <button
                                className="send__btn"
                                onClick={props.forgotEmployeeID}
                            >
                                Send
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>

                    {props.showModalAlert ? (
                        <Alert
                            severity={props.severity}
                            sx={{ marginTop: "2%" }}
                        >
                            {props.message}
                        </Alert>
                    ) : null}
                </div>
            </Typography>
        </Box>
    );
};

export default ForgotEmployeeID;
