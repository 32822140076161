import Navigation from "../Navigation";
import "./staffManagement.scss";
import { useNavigate } from "react-router-dom";
// table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, link) {
  return { name, link };
}

const rows = [
  createData('Uniform Form', '/staff-management/form/uniform-form'),
  createData('Return To Work', '/staff-management/form/return-to-work'),
  createData('Conversation Log', '/staff-management/form/conversation-log'),
  createData('Key Holders Form', '/staff-management/form/key-holders-form'),
  createData('Leavers Form', '/staff-management/form/leavers-form'),
  createData('HIG Chat', '/staff-management/form/hig-chat'),
  createData('Till Agreement', '/staff-management/form/till-agreement'),
];

export default function ConversationsHome() {
  const navigate = useNavigate()
  return (
    <main className="staff_management">
      <Navigation />
      <h1>Employee Forms/Agreements</h1>
      <h3>Select one of the following forms</h3>
      <TableContainer component={Paper} sx={{ maxWidth: '80vw', margin: "1% auto", '@media(max-width: 690px)': { maxWidth: "90vw" } }}>
        <Table sx={{ maxWidth: '100%' }} aria-label="table">
          <TableHead>
            <TableRow >
              <TableCell sx={{ textAlign: 'center', border:"5px solid #bf1a1a", backgroundColor: '#000', color: '#fff' }}>AVAILABLE FORMS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                className="table_row"
                onClick={() => navigate(row.link)}
              >
                <TableCell component="th" scope="row" sx={{ '&:hover': { color: "#fff" } }} >
                  {row.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </main>
  );
}
