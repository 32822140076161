import Navigation from "../Navigation";
import HandbookPdf from "../docs/RaceTrack_Company_Handbook-converted.pdf";

const Handbook = () => {
    return (
        <main className="handbook">
            <Navigation />
            <object
                data={HandbookPdf}
                type="application/xhtml+xml"
                width="100%"
                height="1000px"
            >
                <p>Oops! Your browser doesn't support PDFs!</p>
                <p>
                    <a href="https://staff.racetrackpitstop.co.uk/wp-content/uploads/2020/08/RaceTrack_Company_Handbook-converted.pdf">
                        Download Instead
                    </a>
                </p>
            </object>
        </main>
    );
};

export default Handbook;
