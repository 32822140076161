import { useEffect,useState } from "react";

export default function SelectYesNo(props) {
  const [answer, setAnswer] = useState(props.answer)
  const [disabled, setDisabled] = useState(false)
  useEffect(() => {
    setAnswer(props.answer)
    if(props.dbSuccess!=null){
        setDisabled(true)
    }else{
        setDisabled(false)
    }
  }, [props])

  const updateAnswer = async (val) => {
    setAnswer(val)
    props.handleQuestions(props.idx, val)
  }

  return (
    <select value={answer} onChange={(e) => updateAnswer(e.target.value)} disabled={disabled}>
      <option>--Please select--</option>
      <option>Yes</option>
      <option>No</option>
    </select>
  );
}
