import { useEffect, useState } from "react";
import "../App.scss";
import Logo from "./images/logo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import { Alert } from "@mui/material";
import { encryptData } from "../utils/crypto";

export default function FirstSigned() {
  const url = process.env.REACT_APP_API_URL;
  // const url = "http://10.0.0.174:4030";

  const navigate = useNavigate();
  const config = { headers: { "Content-Type": "application/json" } };
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [id, setId] = useState("");
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let id = queryParams.get("id");
    setId(id)

    //check if the password is null, otherwise redirect to /
    axios
      .get(`${url}/portal/login/status/${id}`)
      .then((res) => {
        console.log(res.data)
        if (res.data === false){
            navigate("/")
        }
      })
      .catch((err) => {
        console.log(`Could not get the details for that id: ${err}`);
      });
  }, []);

  const handleLogin = () => {
    if (password != confirmPassword) {
      setMessage("Password do not match, please ensure they match");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else if (password === "" && confirmPassword === "") {
      setMessage("Password cannot be blank");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else if (id === "") {
      setMessage("Employee ID was not provided");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2500);
    } else {
      //send passwords to back end
      let data = {
        username: id,
        password: encryptData(password, process.env.REACT_APP_EMP_KEY),
      };
      axios
        .post(`${url}/portal/login`, data, config)
        .then((res) => {
          localStorage.setItem("user", id);
          sessionStorage.setItem("token", res.data.token);
          navigate(`/${id}`);
        })
        .catch((err) => {
          setMessage(`${err.response.data.msg}`);
          setSeverity("error");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            setSeverity("");
            document.getElementById("login_button").style = `background: #fff;
            font-size: 14px;
            margin-top: 30px;
            padding: 16px 20px;
            border-radius: 26px;
            text-transform: uppercase;
            font-weight: 700;
            display: flex;
            align-items: center;
            text-align: center;`;
          }, 3500);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <main className="home__page">
      <img src={Logo} alt="logo" />
      <h1>Welcome to the staff portal, please create a password</h1>
      <div className="login__field">
        <LockIcon className="login_icon" />
        <input
          type="password"
          placeholder="Please insert a password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="login__field">
        <LockIcon className="login_icon" />
        <input
          type="password"
          placeholder="Confirm your password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>

      {!showAlert ? (
        <button
          className="login_button"
          onClick={handleLogin}
          id="login_button"
        >
          Login
        </button>
      ) : (
        <Alert severity={severity} sx={{ marginTop: "2%" }}>
          {message}
        </Alert>
      )}
    </main>
  );
}
