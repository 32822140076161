import axios from "axios";

const config = { headers: { "Content-Type": "application/json" } };
const url = process.env.REACT_APP_API_URL;

const getCompanies = (tok) => {
  let newConfig = config;
  if (tok) {
    newConfig = {
      headers: { "Content-Type": "application/json", "x-auth-token": tok },
    };
  }
  return axios
    .get(`${process.env.REACT_APP_API_URL}/company`, newConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getEmployeeDetails = (user, token) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  return axios
    .get(`${url}/portal/accounts/user/${user}`, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

const getSites = () => {
  return axios
    .get("https://nitrous.rt-itservices.co.uk/sites/data")
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getRoles = () => {
  return axios
    .get(`${url}/roles`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const getEmployeesByCompany = (companyId, token) => {
  let localConfig;
  localConfig = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  return axios
    .get(`${url}/employee/companyId/${companyId}`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getFormEmployees = (companyId, employeeDetailsId, token) => {
  let localConfig;
  localConfig = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  return axios
    .get(`${url}/employee/forms/companyId/${companyId}/${employeeDetailsId}`, localConfig)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const getS3Link = (data, config) => {
  return axios
    .post(`${url}/employee/docupload-linkgenerator`, data, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get the link from S3: ${err}`);
      return [];
    });
};

const getLetterTypes = (config) => {
  return axios
    .get(`${url}/employee/letterTypes`, config)
    .then((res) => res.data)
    .catch((err) => {
      console.log(`Could not get letter types: ${err}`);
      return [];
    });
};

export {
  getRoles,
  getSites,
  getCompanies,
  getEmployeeDetails,
  getEmployeesByCompany,
  getFormEmployees,
  getS3Link,
  getLetterTypes,
};
