import { useState } from "react";
import Button from "@mui/material/Button";
import UndoIcon from "@mui/icons-material/Undo";
import FormHelperText from "@mui/material/FormHelperText";

export default function KeyHolders(props) {

    const [keyName, setKeyName] = useState("");
    const [keyList, setKeyList] = useState([]);

    const handleValue = (value) => {
        setKeyName(value);
    }

    const addKey = () => {

        if (keyName != "") {
            let fIndex = keyList.findIndex((key) => key.name === keyName);
            if (fIndex < 0) {
                setKeyList((kList) => [
                    ...kList,
                    {name:keyName}
                ]);
                var tempArray = [...keyList, {name:keyName}]
                props.updateFormData(tempArray)
                setKeyName("")
            }
        } else {
            document.getElementById("alert").style.display = "inline-block";
            setTimeout(() => {
                document.getElementById("alert").style.display = "none";
            }, 2500);
        }
    }

    const removeKey = (i) => {
        let removeItem = keyList.filter((val, index) => index !== i);
        setKeyList(removeItem);
        props.updateFormData(removeItem)
    }

    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            addKey();
        }
    };

    return (
        <main className="staff_management">
            <h1>Key Holders Form</h1>
            <section className="conversation_form">

                <label>List below the keys given
                    <span className="label">*</span> <span id="alert">Please add the Keys required</span>
                </label>
                <input type="text" onChange={(e) => handleValue(e.target.value)} value={keyName} maxLength={50} onKeyDown={handleEnter} />
                <Button onClick={addKey}>Add Additional Key</Button>

                <table style={{ tableLayout: "fixed", width: "50%" }}>
                    {keyList.length > 0 ? keyList.map((key, i) => {
                        return (
                            <tr>
                                <td style={{ wordBreak: "break-all" }}>
                                    {key.name}
                                </td>
                                <td>
                                    <UndoIcon onClick={() => removeKey(i)} />
                                </td>
                            </tr>
                        );
                    }) : <></>}
                </table>

                <FormHelperText>IE. "Shutter Fob", "Front Door Key"</FormHelperText>

                <p>This signed document is a promise to return the above item/s on termination of my employment with the company or if requested by management. I understand that these items are for work purposes only and that it is my responsibility to ensure they are safe.</p>
                <p>In the event that the keys are lost under my possession, I agree that the employer has the right to deduct the cost of a new set of keys, or installing a new lock system.</p>
                <p>I further agree that my employer has the right to deduct the cost of these item/s from my wages if I fail to return the items when requested or in the termination of my employment.</p>
            </section>


        </main>
    )
}