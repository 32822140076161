import { useEffect, useState } from "react";

export default function EaseToStore(props) {

  const [answer, setAnswer] = useState(props.answer)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setAnswer(props.answer)
    if(props.dbSuccess!=null){
        setDisabled(true)
    }else{
        setDisabled(false)
    }
  }, [props])


  const updateAnswer = async (val) => {
    setAnswer(val)
    props.handleQuestions(props.idx, val)
  }

  return (



    <select value={answer} onChange={(e) => updateAnswer(e.target.value)} disabled={disabled}>
      <option>--Select which one applies--</option>
      <option>Less than 30 Minutes</option>
      <option>More than 30 Minutes</option>
    </select>
  );
}
