import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import UndoIcon from "@mui/icons-material/Undo";
import FormHelperText from "@mui/material/FormHelperText";

export default function Uniform(props) {
  const [uniform, setUniform] = useState("");
  const [uniformQty, setUniformQty] = useState("");

  const [uniformItems, setUniformItems] = useState([]);

  const addUniform = () => {
    if (uniform != "" && parseFloat(uniformQty) > 0) {
      var fInxed = uniformItems.findIndex((uni) => uni.uniform == uniform);

      if (fInxed < 0 && uniformItems.length < 20) {
        setUniformItems((uniforms) => [
          ...uniforms,
          {
            uniform: uniform,
            qty: uniformQty,
          },
        ]);
        var tempArray = [...uniformItems,{
          uniform: uniform,
          qty: uniformQty,
        }]
        props.updateFormData(tempArray)
        setUniform("");
        setUniformQty("");
      }
    } else {
      document.getElementById("alert").style.display = "inline-block";
      setTimeout(() => {
        document.getElementById("alert").style.display = "none";
      }, 2500);
    }
  };

  const removeUniform = (i) => {
    console.log(`REMOVE `, i);
    let removeItem = uniformItems.filter((val, index) => index !== i);
    setUniformItems(removeItem);
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      addUniform();
    }
  };

  return (
    <main className="staff_management">
      <h1>Uniform Form</h1>

      <section className="conversation_form">
        <label>
          Uniform Given: <span className="label">*</span>{" "}
          <span id="alert">Please fill in a Uniform Item and a Quantity</span>
        </label>

        <table>
          <tr>
            <th>Uniform Item</th>
            <th>Quantity</th>
            <th></th>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                value={uniform}
                maxLength={50}
                onChange={(e) => setUniform(e.target.value)}
                style={{ width: "90%" }}
                onKeyDown={handleEnter}
              />
            </td>
            <td>
              <input
                type="number"
                pattern="[0-9]*"
                value={uniformQty}
                min={0}
                onChange={(e) => setUniformQty(e.target.value)}
                style={{ width: "90%" }}
                onKeyDown={handleEnter}
              />
            </td>
            <td>
              <AddIcon onClick={() => addUniform()} />
            </td>
          </tr>

          {uniformItems.length > 0
            ? uniformItems.map((uniform, i) => {
                return (
                  <tr>
                    <td style={{ wordBreak: "break-all" }}>
                      {uniform.uniform}
                    </td>
                    <td>{uniform.qty}</td>
                    <td>
                      <UndoIcon onClick={() => removeUniform(i)} />
                    </td>
                  </tr>
                );
              })
            : null}
        </table>

        <br />

        <FormHelperText>
        Add what uniform (EG: RaceTrack Polo XL, Tubbees Fleece XS) and the quantity given. Add a new line for each item type
        </FormHelperText>

        <label>
        This signed document is a promise to return the above items on termination of my employment with the company or if requested by management. 
        I also understand that these items are for work purposes only and should be laundered and returned within 5 working days to avoid deduction.
        </label>
        <br />
        <label>
        I further agree that my employer has the right to deduct the cost of these items from my wages if 
        I fail to return the items when requested or in the termination of my employment.
        <br/>The cost of these are as follows:
        </label>

        <FormHelperText>£20 per Polo Shirt if not returned</FormHelperText>
        <FormHelperText>£20 per Hat if not returned</FormHelperText>
        <FormHelperText>£20 per Apron if not returned</FormHelperText>
        <FormHelperText>£30 per Fleece if not returned</FormHelperText>
        <FormHelperText>£5 per Name Badge if not returned</FormHelperText>
        <br />
        <label>
        The below items are Personal Protective Equipment (PPE). Similar to any items of uniform that is provided 
        to you this is company property and should not be removed from the premises without prior authorisation. 
        <br/>
        If your employment is terminated for any reason, or you resign, and you do not return the PPE provided 
        to you, within five days, then you will be held accountable for this cost:
        </label>

        <FormHelperText>£5.00 - High-Vis Jacket</FormHelperText>
        <FormHelperText>£46.00 - Lightweight Bomber Jacket (Waterproof)</FormHelperText>
        <FormHelperText>£10.99 - Waterproof Trousers</FormHelperText>
        <FormHelperText>£15.99 - Wellingtons</FormHelperText>
        
        <br/>
        <label>
        Should the uniform be returned in a state that is unusable, or the uniform is not laundered, 
        I agree that the company can charge a cleaning fee. 
        </label>
        <label>
        This will normally be charged at half the value of the uniform; however, the Senior Management 
        team will review this on a case-by-case basis.
        </label>
      </section>
    </main>
  );
}
