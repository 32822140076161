import { useEffect, useState } from "react";
import Logo from "./images/logo.png";
import LockIcon from "@mui/icons-material/Lock";
import { encryptData } from "../utils/crypto";
import { Alert } from "@mui/material";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export default function PassReset() {
  const [id, setId] = useState();
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const urlHr =  process.env.REACT_APP_API_URL;
  const navigate = useNavigate()
  


  useEffect(() => {
    const url = new URL(window.location.href);
    let id = url.searchParams.get("id");
    id = id.replaceAll(" ", "+")
    console.log(id)

    setId(id)
  }, []);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handlePasswordChange();
    }
  };

  const handlePasswordChange = () => {
    if (password != confirmPass) {
        setMessage("Please make sure password match")
        setSeverity("warning")
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
            setMessage("")
            setSeverity("")
        },3000)
    } else if(password === ""){
      setMessage("Password cannot be empty")
      setSeverity("warning")
      setShowAlert(true)
      setTimeout(() => {
          setShowAlert(false)
          setMessage("")
          setSeverity("")
      },3000)
    } else{
        let data = {
            id: id,
            password: encryptData(password, process.env.REACT_APP_EMP_KEY)
        }
        axios
        .post(`${urlHr}/portal/login/reset`, data)
        .then((res) => {
            navigate("/")
        }).catch((err) => {
            console.log(err)
            setMessage("Something went wrong, please try again")
            setSeverity("error")
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
                setMessage("")
                setSeverity("")
            },3000)
        })
    }
  };

  return (
    <main className="home__page">
      <img src={Logo} alt="logo" />
      <h1>Please set a new password</h1>
      <div className="login__field">
        <LockIcon className="login_icon" />
        <input
          type="password"
          placeholder="Your new password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="login__field">
        <LockIcon className="login_icon" />
        <input
          type="password"
          placeholder="Confirm password"
          onChange={(e) => setConfirmPass(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <button className="login_button" onClick={handlePasswordChange}>Reset password</button>
      {showAlert ? (
        <Alert severity={severity} sx={{ marginTop: "2%" }}>
          {message}
        </Alert>
      ) : null}
    </main>
  );
}
