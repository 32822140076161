import { useEffect, useState } from "react";

export default function TextArea(props) {
    const [answer, setAnswer] = useState(props.answer)
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        setAnswer(props.answer)
        if(props.dbSuccess!=null){
            setDisabled(true)
        }else{
            setDisabled(false)
        }
    }, [props])

    const updateAnswer = async (val) => {
        setAnswer(val)
        props.handleQuestions(props.idx, val)
    }

    return (
        <>
            <textarea cols={40} rows={6} value={answer} onChange={(e) => updateAnswer(e.target.value)} disabled={disabled} />
        </>
    )
}