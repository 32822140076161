import './notfound.scss';
import Logo from '../../images/logo.png';
import viks from './vik.png';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return(
        <main className="not_found_main">
            <img src={Logo} alt="Logo" />
            <section className="not__found">
                <h1>4<span><img src={viks} alt="viks" width="90%"/></span>4</h1>
                <h2>Sorry, we lost this page</h2>
                <Link to="/" className="button">back home</Link>
            </section>
        </main>
    )
}