import Navigation from "../Navigation";
import { useState, useEffect } from "react";
import axios from "axios";
import "../styles/form.scss";
import { getEmployeeDetails } from "../../utils/apiUtils";
import moment from "moment";
import { Button, Alert } from "@mui/material";
import Tick from "../animations/Tick";

export default function HolidayForm() {
    const [total, setTotal] = useState("");
    const [reason, setReason] = useState("");
    const [firstDay, setFirstDay] = useState("");
    const [lastDay, setLastDay] = useState("");
    const [headOffice, setHeadOffice] = useState(false);
    const [paid, setPaid] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [user, setUser] = useState("");
    //alerts
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [showTick, setShowTick] = useState(false);
    const [token, setToken] = useState("");
    const config = {
        headers: { "Content-Type": "application/json", "x-auth-token": token },
    };

    const today = moment().format("YYYY-MM-DD");
    const urlHr = process.env.REACT_APP_API_URL;

    useEffect(() => {
        let user = localStorage.getItem("user");
        const token = sessionStorage.getItem("token");
        setToken(token);

        getEmployeeDetails(user, token)
            .then((data) => {
                // console.log(data);
                setUser(data.employeeDetailsId);
                if (data.site.name == "Head Office") {
                    setHeadOffice(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleDateCheck = (first, last) => {

        if((first !== null || first != "") && (last != null || last != "")) {

            if(moment(first).isAfter(last)){

                setLastDay(first)

                setMessage("Your first day cannot be after your last day, your last day has been updated");
                document.getElementById("first_day_alert").style.display =
                    "flex";
                setTimeout(() => {
                    document.getElementById("first_day_alert").style.display =
                        "none";
                    setMessage("");
                }, 3500);


            }
        }
    }


    //submits
    const handleSubmit = () => {
        document.getElementById("ho_btn").style.display = "none";
        //checks all fields are filled
        if (firstDay === "" || total === "") {
            setMessage("Please make sure all fields are filled");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("ho_btn").style.display = "flex";
            }, 3000);
        } else if (headOffice && (paid === "" || paid === "--Select One--")) {
            setMessage("Select if you want your holidays paid or unpaid");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("ho_btn").style.display = "flex";
            }, 3000);
        } else {
            //total days must be a number
            if (isNaN(total)) {
                setMessage("Total days must be a number, please try again");
                setSeverity("warning");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setMessage("");
                    setSeverity("");
                    document.getElementById("ho_btn").style.display = "flex";
                }, 3000);
            } else if (isNaN(user)) {
                setMessage("Submission Error! Please Logout And Try Again.");
                setSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setMessage("");
                    setSeverity("");
                    document.getElementById("ho_btn").style.display = "flex";
                }, 3000);
            } else {
                let data = {};
                if (headOffice) {
                    data = {
                        paid: paid,
                        startDate: firstDay,
                        endDate: lastDay ? lastDay : firstDay,
                        days: total,
                        reason: reason,
                        employeeDetailsId: user,
                    };
                } else {
                    data = {
                        paid: 1,
                        startDate: firstDay,
                        endDate: lastDay ? lastDay : firstDay,
                        days: total,
                        reason: reason,
                        employeeDetailsId: user,
                    };
                }

                axios
                    .post(`${urlHr}/holiday/submit/new`, data, config)
                    .then((res) => {
                        console.log(res.data);
                        if (res.data === true) {
                            setShowTick(true);
                            setTimeout(() => {
                                setPaid("");
                                setFirstDay("");
                                setLastDay("");
                                setTotal("");
                                setReason("");
                                setShowTick(false);
                                document.getElementById(
                                    "ho_btn"
                                ).style.display = "flex";
                            }, 3000);
                        } else {
                            setMessage(
                                "Something went wrong, please try again"
                            );
                            setSeverity("error");
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                                setMessage("");
                                setSeverity("");
                                document.getElementById(
                                    "ho_btn"
                                ).style.display = "flex";
                            }, 3000);
                        }
                    })
                    .catch((err) => {
                        setMessage(err.response.data);
                        setSeverity("error");
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setMessage("");
                            setSeverity("");
                            document.getElementById("ho_btn").style.display =
                                "flex";
                        }, 3000);
                    });
            }
        }
    };

    return (
        <main>
            <Navigation />
            <section className="form__main">
                <section className="form">
                    <label>
                        First holiday day <span>*</span>
                    </label>
                    <p id="first_day_alert">{message}</p>
                    <input
                        type="date"
                        min={today}
                        onChange={(e) =>
                            // handleFirstDate(e.target.value)
                            setFirstDay(e.target.value)
                        }
                        value={firstDay ? firstDay : today}
                        onBlur={() => handleDateCheck(firstDay, lastDay)}
                    />
                    <label>
                        Last holiday day <span>*</span>
                    </label>
                    <p id="last_day_alert">{message}</p>
                    <input
                        type="date"
                        min={firstDay ? firstDay : today}
                        onChange={(e) =>
                            // handleLastDate(e.target.value)
                            setLastDay(e.target.value)
                        }
                        value={lastDay ? lastDay : firstDay}
                        onBlur={() => handleDateCheck(firstDay, lastDay)}

                    />
                    <label>
                        Total days <span>*</span>
                    </label>
                    <input
                        type="number"
                        onChange={(e) => setTotal(e.target.value)}
                        value={total}
                        pattern="[0-9]+([\.,][0-9]+)?"
                        min={0}
                    />
                    {headOffice ? (
                        <>
                            <label>How do you want your holidays?</label>
                            <select
                                onChange={(e) => setPaid(e.target.value)}
                                value={paid}
                            >
                                <option>--Select One--</option>
                                <option value={1}>Paid</option>
                                <option value={2}>Unpaid</option>
                            </select>
                        </>
                    ) : null}
                    <label>Reason for holiday</label>
                    <textarea
                        cols={45}
                        rows={5}
                        onChange={(e) => setReason(e.target.value)}
                        value={reason}
                    />
                    <Button
                        variant="contained"
                        color="success"
                        id="ho_btn"
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </Button>
                    {showAlert ? (
                        <Alert severity={severity} sx={{ marginTop: "1%" }}>
                            {message}
                        </Alert>
                    ) : showTick ? (
                        <Tick />
                    ) : (
                        null
                    )}
                </section>
            </section>
        </main>
    );
}
