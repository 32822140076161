import { Divider } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function Rtw(props) {

  const [firstDay, setFirstDay] = useState("");
  const [lastDay, setLastDay] = useState("");
  const [returnDay, setReturnDay] = useState("");
  const [daysOff, setDaysOff] = useState(0);
  const [call, setCall] = useState("");
  const [speakTo, setSpeakTo] = useState("N/A");
  const [phoneTime, setPhoneTime] = useState("");
  const [absenceReason, setAbsenceReason] = useState("");
  const [feelBetter, setFeelBetter] = useState("");
  const [enoughNotice, setEnoughNotice] = useState("");
  const [consultGp, setConsultGp] = useState("");
  const [absenceFactors, setAbsenceFactors] = useState("");
  const [overallPattern, setOverallPattern] = useState("");
  const [disability, setDisability] = useState("");
  const [comments, setComments] = useState("");

  const handleDays = (last) => {
    if (firstDay === "") {
      document.getElementById("alert").style.display = "inline-block";
      setTimeout(() => {
        document.getElementById("alert").style.display = "none";
        document.getElementById("lastDay").value = "";
      }, 2500);
    } else {
      let first = moment(firstDay);
      let lastDay = moment(last);

      let totalDays = lastDay.diff(first, "days") + 1;

      setDaysOff(totalDays);
    }
  };

  const update = async (val, type) => {
    var data = {
      firstDay: firstDay,
      lastDay: lastDay,
      returnDay: returnDay,
      daysOff: daysOff,
      call: call,
      speakTo: speakTo,
      phoneTime: phoneTime,
      absenceReason: absenceReason,
      feelBetter: feelBetter,
      enoughNotice: enoughNotice,
      consultGp: consultGp,
      absenceFactors: absenceFactors,
      overallPattern: overallPattern,
      disability: disability,
      comments: comments
    }

    data[type] = val;

    props.updateFormData(data);
  }

  return (
    <main className="staff_management">
      <h1>Return To Work Form</h1>
      <h2>
        This form must be completed after any period of absence other than
        holiday. Employees must complete ALL questions.
      </h2>
      <section className="conversation_form">
        <label>
          First day of absence <span className="label">*</span>
        </label>
        <input type="date" onChange={(e) => { setFirstDay(e.target.value); update(e.target.value, "firstDay") }} />
        <label>
          Last day of absence <span className="label">*</span>
          <span id="alert">Select first day of absence first</span>
        </label>
        <input
          type="date"
          onChange={(e) => { setLastDay(e.target.value); handleDays(e.target.value); update(e.target.value, "lastDay") }}
          id="lastDay"
        />
        <label>Return to work date:</label>
        <input type="date" onChange={(e) => { setReturnDay(e.target.value); update(e.target.value, "returnDay") }} />

        <label>
          Total number of working days absent:{" "}
          <span className="label">
            {daysOff === 0 ? <>SELECT ABSENT DAYS</> : daysOff}
          </span>{" "}
        </label>
        <h1>CONTACTING THE COMPANY</h1>
        <Divider sx={{ width: "100%", margin: "1% auto" }} />
        <FormControl onChange={(e) => setCall(e.target.value)}>
          <FormLabel>
            Did you telephone your manager/HR at the commencement of your
            absence?
          </FormLabel>
          <RadioGroup aria-labelledby="rtw" name="rtw">
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {call === "yes" ? (
          <>
            <label>
              Who did you speak to?
              <span className="label">*</span>
            </label>
            <input type="text" maxLength={40} onChange={(e) => { setSpeakTo(e.target.value); update(e.target.value, "speakTo") }} />
          </>
        ) : null}
        <label>
          What time did you phone in?
          <span className="label">*</span>
        </label>
        <input type="time" onChange={(e) => { setPhoneTime(e.target.value); update(e.target.value, "phoneTime") }} />
        <label>
          Reason for absence?
          <span className="label">*</span>
        </label>
        <input type="text" onChange={(e) => { setAbsenceReason(e.target.value); update(e.target.value, "absenceReason") }} />
        <label>
          Do you feel better &amp; fit to get back to work?
          <span className="label">*</span>
        </label>
        <input type="text" onChange={(e) => { setFeelBetter(e.target.value); update(e.target.value, "feelBetter") }} />
        <Divider sx={{ width: "100%", margin: "1% auto" }} />
        <FormControl>
          <FormLabel>
            Did the employee give at least 6h notice for their absence?
          </FormLabel>
          <RadioGroup aria-labelledby="rtw" name="rtw" onChange={(e) => { setEnoughNotice(e.target.value); update(e.target.value, "enoughNotice") }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Did the employee consult with their GP?</FormLabel>
          <RadioGroup aria-labelledby="rtw" name="rtw" onChange={(e) => { setConsultGp(e.target.value); update(e.target.value, "consultGp") }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>
            Did The Employee Indicate That Factors At Work May Have Caused Or
            Contributed To The Absence?
          </FormLabel>
          <RadioGroup aria-labelledby="rtw" name="rtw" onChange={(e) => { setAbsenceFactors(e.target.value); update(e.target.value, "absenceFactors") }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ width: "100%", margin: "1% auto" }} />

        <FormControl>
          <FormLabel>Is this absence part of an overall pattern?</FormLabel>
          <RadioGroup aria-labelledby="rtw" name="rtw" onChange={(e) => { setOverallPattern(e.target.value); update(e.target.value, "overallPattern") }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Does the employee have any type of disability?</FormLabel>
          <RadioGroup aria-labelledby="rtw" name="rtw" onChange={(e) => { setDisability(e.target.value); update(e.target.value, "disability") }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        <label>
          Any further comments from the manager/HR
          <span className="label">*</span>
        </label>
        <textarea onChange={(e) => { setComments(e.target.value); update(e.target.value, "comments") }} />
      </section>
    </main>
  );
}
