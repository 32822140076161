import "../staffManagement.scss";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { Divider } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";

export default function Conversation(props) {
  const [value, setValue] = useState(0);
  const [type, setType] = useState("")
  const [missedShift, setMissedShift] = useState("");
  const [conductFirst, setConductFirst] = useState("");
  const [other, setOther] = useState(null);
  const [probationPassed, setProbationPassed] = useState(null);
  const [discussion, setDiscussion] = useState(null);
  const [performanceRating, setPerformanceRating] = useState(null);
  const [performanceReasons, setPerformanceReasons] = useState([]);
  const [reason, setReason] = useState(null);
  const [notes, setNotes] = useState(null);
  const [issue, setIssue] = useState(null);
  const [firstTime, setFirstTime] = useState(null);
  const [warningType, setWarningType] = useState(null);
  const [firstAbsence, setFirstAbsence] = useState(null);
  const [lastAbsence, setLastAbsence] = useState(null);
  const [help, setHelp] = useState(null);
  const [remainOnTime, setRemainOnTime] = useState(null);
  const [comments, setComments] = useState(null);

  const handleValue = (value) => {
    setValue(value);
    if (value == 1) {
      setType("Time & Attendance")
    } else if (value == 2) {
      setType("Probation")
    } else if (value == 3) {
      setType("Performance")
    } else if (value == 4) {
      setType("Conduct")
    } else {
      setType("Other")
    }

    setOther(null);
    setProbationPassed(null);
    setDiscussion(null);
    setPerformanceRating(null);
    setReason(null);
    setNotes(null);
    setIssue(null);
    setFirstTime(null);
    setWarningType(null);
    setFirstAbsence(null);
    setLastAbsence(null);
    setHelp(null);
    setRemainOnTime(null);
    setComments(null);
    setPerformanceReasons([])
  };

  const update = async (val, field, name) => {
    let data = {
      type: type,
      other: other,
      probationPassed: probationPassed,
      discussion: discussion,
      performanceRating: performanceRating,
      performanceReasons: performanceReasons,
      reason: reason,
      notes: notes,
      issue: issue,
      firstTime: firstTime,
      warningType: warningType,
      missedShift: missedShift,
      firstAbsence: firstAbsence,
      lastAbsence: lastAbsence,
      help: help,
      remainOnTime: remainOnTime,
      comments: comments
    }

    if (field == "performanceReasons") {
      var fInxed = performanceReasons.findIndex((perf) => perf.reason == name);
      let tempArray = []

      if (fInxed < 0) {
        setPerformanceReasons((performanceReasons) => [
          ...performanceReasons,
          { reason: name },
        ]);

        tempArray = [...performanceReasons, { reason: name }]
      } else {
        var filteredArr = performanceReasons.filter((perf) => perf.reason !== name)
        setPerformanceReasons(filteredArr)
        tempArray = filteredArr;
      }

      data[field] = tempArray;
    } else {
      data[field] = val;
    }

    props.updateFormData(data)
  };

  return (
    <main className="staff_management">
      <h1>Conversation Log</h1>
      <section className="conversation_form">
        <FormControl onChange={(e) => handleValue(e.target.value)}>
          <FormLabel>Reason for Conversation</FormLabel>
          <RadioGroup
            aria-labelledby="conversation-reasons"
            name="conversation-reasons"
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Time & Attendance"
            />
            <FormControlLabel value={2} control={<Radio />} label="Probation" />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="Performance"
            />
            <FormControlLabel value={4} control={<Radio />} label="Conduct" />
            <FormControlLabel value={5} control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        {value == 1 ? (
          <>
            <label>
              Issue with Time & Attendance <span className="label">*</span>
            </label>
            <select onChange={(e) => { setMissedShift(e.target.value); update(e.target.value, "missedShift") }} >
              <option value={null}>--Please select one--</option>
              <option value="Lateness">Lateness</option>
              <option value="Missed shift/Absence">Missed shift/Absence</option>
            </select>
            {missedShift === "Missed shift/Absence" ? (
              <>
                <label>
                  Date of first day absent <span className="label">*</span>
                </label>
                <input type="date" onChange={(e) => { setFirstAbsence(e.target.value); update(e.target.value, "firstAbsence") }} />
                <label>
                  Date of last day absent <span className="label">*</span>
                </label>
                <input type="date" onChange={(e) => { setLastAbsence(e.target.value); update(e.target.value, "lastAbsence") }} />
              </>
            ) : null}
            <label>
              Reasons for being absent/late? <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setReason(e.target.value); update(e.target.value, "reason") }} />
            <strong>How can we help?</strong>
            <FormHelperText>
              Ask the following questions and note the reply accurately
            </FormHelperText>
            <Divider sx={{ width: "100%", margin: "1% auto" }} />
            <label>
              {missedShift=="Missed shift/Absence"?
              <>Is there anything the company can do to ensure that you are able to attend your shifts?</>
              :
              <>Is there anything that the company can do to ensure that you are on time?</>
              }
              {" "}
              <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setHelp(e.target.value); update(e.target.value, "help") }} />
            <label>
              Comments on any further information{" "}
              <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setComments(e.target.value); update(e.target.value, "comments") }} />
          </>
        ) : value == 2 ? (
          <>
            <FormControl>
              <FormLabel>Is this person passing their probation?</FormLabel>
              <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setProbationPassed(e.target.value); update(e.target.value, "probationPassed") }} >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel
                  value="Extension Previously Agreed With HR"
                  control={<Radio />}
                  label="Extension Previously Agreed With HR"
                />
              </RadioGroup>
            </FormControl>
            <label>
              Discussion on Probation review <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setDiscussion(e.target.value); update(e.target.value, "discussion") }} />
          </>
        ) : value == 3 ? (
          <>
            <FormControl>
              <FormLabel>How is their performance?</FormLabel>
              <RadioGroup aria-labelledby="Performance" name="Performance" onChange={(e) => { setPerformanceRating(e.target.value); update(e.target.value, "performanceRating") }} >
                <FormControlLabel
                  value="Good"
                  control={<Radio />}
                  label="Good"
                />
                <FormControlLabel
                  value="Satisfactory"
                  control={<Radio />}
                  label="Satisfactory (small things require attention)"
                />
                <FormControlLabel
                  value="Average"
                  control={<Radio />}
                  label="Average"
                />
                <FormControlLabel
                  value="Poor"
                  control={<Radio />}
                  label="Poor (More attention/training required)"
                />
                <FormControlLabel
                  value="Unsatisfactory"
                  control={<Radio />}
                  label="Unsatisfactory"
                />
              </RadioGroup>
            </FormControl>

            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
              <FormLabel component="legend">
                Reasons for performance note <span className="label">*</span>
              </FormLabel>
              <FormHelperText>Select as many as needed</FormHelperText>
              <FormGroup >
                <FormControlLabel
                  control={<Checkbox name="Managing the Till" />}
                  label="Managing the Till" onChange={(e) => { update(e.target.value, "performanceReasons", "Managing the Till") }}
                />
                <FormControlLabel
                  control={<Checkbox name="Customers" />}
                  label="Dealing with customers" onChange={(e) => { update(e.target.value, "performanceReasons", "Dealing with customers") }}
                />
                <FormControlLabel
                  control={<Checkbox name="stock" />}
                  label="Stock" onChange={(e) => { update(e.target.value, "performanceReasons", "Stock") }}
                />
                <FormControlLabel
                  control={<Checkbox name="other" />}
                  label="Other" onChange={(e) => { update(e.target.value, "performanceReasons", "Other") }}
                />
              </FormGroup>
            </FormControl>
            <label>
              Notes on performance <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setNotes(e.target.value); update(e.target.value, "notes") }} />
          </>
        ) : value == 4 ? (
          <>
            <label>
              Concerns highlighted <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setIssue(e.target.value); update(e.target.value, "issue") }} />
            <FormControl onChange={(e) => setConductFirst(e.target.value)}>
              <FormLabel>
                Is this the staff member's first time to have a conduct related
                conversation?
              </FormLabel>
              <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setFirstTime(e.target.value); update(e.target.value, "firstTime") }} >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {conductFirst === "No" ? (
              <>
                <FormControl>
                  <FormLabel>
                    What previous warning(s) have been given to the staff
                    member?
                  </FormLabel>
                  <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setWarningType(e.target.value); update(e.target.value, "warning") }} >
                    <FormControlLabel
                      value="Verbal"
                      control={<Radio />}
                      label="Verbal"
                    />
                    <FormControlLabel
                      value="First Formal Written warning by HR"
                      control={<Radio />}
                      label="First Formal Written warning by HR"
                    />
                    <FormControlLabel
                      value="Final Formal Written warning by HR"
                      control={<Radio />}
                      label="Final Formal Written warning by HR"
                    />
                    <FormControlLabel
                      value="I don't know"
                      control={<Radio />}
                      label="I don't know"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            ) : (
              <FormHelperText sx={firstTime?{fontSize:"1.5rem", fontWeight:"bolder"}:null}>
                Issue a Verbal warning to the employee
              </FormHelperText>
            )}
          </>
        ) : value == 5 ? (
          <>
            <label>
              Other, please state all details below <span className="label">*</span>
            </label>
            <textarea onChange={(e) => { setOther(e.target.value); update(e.target.value, "other") }} />
          </>
        ) : (
          <h2>Please select a reason</h2>
        )}
      </section>
    </main>
  );
}
