import { useParams, useNavigate } from "react-router-dom";
import Navigation from "../../Navigation";
import { useState, useEffect } from "react";
import { decryptData } from "../../../utils/crypto";
import {
  getEmployeeDetails,
  getEmployeesByCompany,
  getCompanies,
  getFormEmployees,
  getLetterTypes,
  getS3Link,
} from "../../../utils/apiUtils";
import Uniform from "./Uniform";
import Rtw from "./Rtw";
import Conversation from "./Conversation";
import KeyHolders from "./KeyHolders";
import Leavers from "./Leavers";
import {
  Button,
  Divider,
  Typography,
  Alert,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText,
} from "@mui/material";
import "../staffManagement.scss";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import stringSimilarity from "string-similarity";
import axios from "axios";
import HigChat from "./HigChat";
import TillAgreement from "./TillAgreement";

export default function FormHome() {
  const { form } = useParams();
  const navigate = useNavigate();
  const today = moment();
  const [token, setToken] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [tempEmployeeList, setTempEmployeeList] = useState([]);
  const [employeeForm, setEmployeeForm] = useState("");
  const [manager, setManager] = useState("");
  const [formType, setFormType] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [signature, setSignature] = useState("");
  const [formData, setFormData] = useState("")
  const [ip, setIp] = useState("")
  const [userProfile,setUserProfile] = useState("")
  const [hiddenSig, setHiddenSig] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [siteList, setSiteList] = useState([])

  //alerts
  const [showAlert, setShowalert] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };

  useEffect(() => {
    const currentUser = localStorage.getItem("user");
    const localToken = sessionStorage.getItem("token");
    setToken(localToken);
    const localConfig = { headers: { "x-auth-token": localToken } };
    try {
      getEmployeeDetails(currentUser, localToken).then((data) => {
        let name = decryptData(data.firstName, process.env.REACT_APP_EMP_KEY);
        let surname = decryptData(data.lastName, process.env.REACT_APP_EMP_KEY);
        setManager({ name: `${name} ${surname}`, id: data.employeeDetailsId });
        getEmployees(data.site.companyId, data.employeeDetailsId, localToken);
        setIsAdmin(data.adminFormAccess)
        setUserProfile(decryptData(data.email, process.env.REACT_APP_EMP_KEY))
      });
    } catch (err) {
      console.log(`Could not get employee details: ${err}`);
    }
    try {
      getCompanies(localToken).then((data) => {
        setSiteList(data)
      })
    } catch (err) {
      console.log(`Could not get company list: ${err}`)
    }
    try {
      getLetterTypes(localConfig).then((types) => {
        //check similarity between the form and form types to select form type
        for (let t = 0; t < types.length; t++) {
          let formFormated = form.replace("-", " ");
          let similarity = stringSimilarity.compareTwoStrings(
            types[t].name.toLowerCase(),
            formFormated
          );
          if (similarity > 0.8) {
            setFormType(types[t].typeId);
          }
        }
      });
    } catch (err) {
      console.log(`Could not get the letter types: ${err}`);
    }

    axios
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIp(res.data.ip);
      })
      .catch((err) => {
        console.log(`Could not get IP Address`);
      });
  }, []);

  const getEmployees = async (siteId, empId, token) => {
    setEmployeeList([]);
    setTempEmployeeList([]);
    await getFormEmployees(siteId, empId, token)
      .then((employees) => {
        for (let l = 0; l < employees.length; l++) {
          setEmployeeList((employeeList) => [
            ...employeeList,
            {
              firstName: decryptData(
                employees[l].firstName,
                process.env.REACT_APP_EMP_KEY
              ),
              lastName: decryptData(
                employees[l].lastName,
                process.env.REACT_APP_EMP_KEY
              ),
              employeeDetailsId: employees[l].employeeDetailsId,
              companyId: employees[l].mainCompanyId
            },
          ]);
          setTempEmployeeList((tempList) => [
            ...tempList,
            {
              firstName: decryptData(
                employees[l].firstName,
                process.env.REACT_APP_EMP_KEY
              ),
              lastName: decryptData(
                employees[l].lastName,
                process.env.REACT_APP_EMP_KEY
              ),
              employeeDetailsId: employees[l].employeeDetailsId,
              companyId: employees[l].mainCompanyId
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(`Could not get the employees for the company: ${err}`);
      });
  };

  const getTargetElement = () => document.getElementById("form");

  function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const updateFormData = async (fData) => {
    setFormData(fData)
  }

  const selectSite = async(compId)=>{
    var filteredList = employeeList.filter((e)=>e.companyId==compId)
    filteredList.sort((a,b)=>a.firstName.localeCompare(b.firstName))
    setTempEmployeeList(filteredList)
  }

  const handleSubmit = async () => {
    setDisabled(true);
    let textAreas = document.getElementsByTagName("textarea");
    let inputs = document.getElementsByTagName("input");
    let count = 0;

    for (let t = 0; t < textAreas.length; t++) {
      if (textAreas[t].value === "") {
        count++;
      }
    }
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].value === "") {
        count++;
      }
    }

    var data123 = ""
    if (!hiddenSig) {
      data123 = signature.getTrimmedCanvas().toDataURL('image/png')
      setSignature(data123)
    }

    // key holder and uniform don't have textareas
    if (form === "uniform-form" && count != 2) {
      console.log("alert");
      setMessage("Please fill all required fields");
      setSeverity("warning");
      setShowalert(true);
      setTimeout(() => {
        setDisabled(false);
        setShowalert(false);
      }, 3000);
    } else if (form === "key-holders-form" && count != 1) {
      console.log("alert");
      setMessage("Please fill all required fields");
      setSeverity("warning");
      setShowalert(true);
      setTimeout(() => {
        setDisabled(false);
        setShowalert(false);
      }, 3000);
    } else if (
      form != "uniform-form" &&
      form != "key-holders-form" &&
      count > 0
    ) {
      setMessage("Please fill all required fields");
      setSeverity("warning");
      setShowalert(true);
      setTimeout(() => {
        setDisabled(false);
        setShowalert(false);
      }, 3000);
    } else if (!hiddenSig && signature.isEmpty()) {
      setMessage("Employee must sign the form, if not present type N/A");
      setSeverity("warning");
      setShowalert(true);
      setTimeout(() => {
        setDisabled(false);
        setShowalert(false);
      }, 3000);
    } else {
      console.log("------GETTING S3 LINK------");
      const data = {
        type: formType,
        form: form,
        employeeDetailsId: parseInt(employeeForm),
        fileName: `${employeeForm}-${form}-by:${manager.name}-${moment().format(
          "YYYY-MM-DD-HH-mm-ss-SSS"
        )}.pdf`,
        formData: JSON.stringify(formData),
        manager: manager.name,
        employee: data123,
        date: today.format("DD/MM/YYYY"),
        ip: ip,
        user:userProfile
      };
      await getS3Link(data, config)
        .then((res) => {
          createPdf(res);
        })
        .catch((err) => {
          console.log(`Could not get s3 link: ${err}`);
          setMessage("Failed while getting s3 link, please try again");
          setSeverity("error");
          setShowalert(true);
          setTimeout(() => {
            setDisabled(false);
            setShowalert(false);
          }, 3000);
        });
    }
  };

  const storeSignature = async (sign) => {
    setSignature(sign.getTrimmedCanvas().toDataURL('image/png'))
  }

  const hideSignature = async (state) => {
    setHiddenSig(state)
  }

  const createPdf = async (details) => {
    const buff = base64ToArrayBuffer(details.file)
    await axios
      .put(details.url, buff)
      .then((res) => {
        console.log("-------UPLOADED-------");
        setMessage("Form uploaded to HR");
        setSeverity("success");
        setShowalert(true);
        //sends alert to HR
        let data = {
          employeeDetailsId: parseInt(employeeForm),
          documentId: parseInt(formType),
          uniqueDocId: details.documentId
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/webhook/hrEmployeeAlert`,
            data,
            config
          )
          .then((res) => {
            console.log(res);
            setDisabled(false);
            setShowalert(false);
            navigate("/staff-management");
          })
          .catch((err) => {
            console.log(`Could not trigger alert: ${err}`);
          });
      })
      .catch((err) => {
        console.log(`Could not upload to S3: ${err}`);
        setMessage("Failed while uploading to S3, please try again");
        setSeverity("error");
        setShowalert(true);
        setTimeout(() => {
          setDisabled(false);
          setShowalert(false);
        }, 3000);
      });
  };

  return (
    <main>
      <Navigation />
      <Typography variant="h6">Who are you doing this form with?</Typography>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          {isAdmin ?
            <>
              <InputLabel>Site</InputLabel>
              <Select
                labelId="select"
                label="Age"
                onChange={(e) => selectSite(e.target.value)}
              >
                {siteList.map((site, index) => {
                  return (
                    <MenuItem value={site.companyId} key={index}>
                      {site.siteName}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
            :
            null
          }
        </FormControl>
        <FormControl fullWidth sx={{marginTop:"10%"}}>
          <InputLabel>Employees</InputLabel>
          <Select
            labelId="select"
            label="Age"
            onChange={(e) => setEmployeeForm(e.target.value)}
          >
            {tempEmployeeList.map((employee, index) => {
              return (
                <MenuItem value={employee.employeeDetailsId} key={index}>
                  {employee.firstName} {employee.lastName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

      </Box>
      {employeeForm != "" ? (
        <>
          <div id="form">
            {form === "uniform-form" ? (
              <Uniform updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : form === "return-to-work" ? (
              <Rtw updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : form === "conversation-log" ? (
              <Conversation updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : form === "key-holders-form" ? (
              <KeyHolders updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : form === "leavers-form" ? (
              <Leavers updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : form === "hig-chat" ? (
              <HigChat updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : form === "till-agreement" ? (
              <TillAgreement updateFormData={updateFormData} hideSignature={hideSignature} />
            ) : null}
            <Divider sx={{ width: "100%", margin: "2% auto" }} />

            {!hiddenSig ?
              <>
                <label>Employee signature:</label>
                <br />
                <Button
                  sx={{ margin: "1% 0" }}
                  color="error"
                  onClick={() => signature.clear()}
                >
                  Clear Signature
                </Button>
                <br />
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ className: "sigCanvas" }}
                  ref={(ref) => {
                    setSignature(ref);
                  }}
                />
                <FormHelperText>
                  Employee signed electronically {today.format("DD/MM/YYYY")}
                </FormHelperText>
              </>
              :
              null}

            <div className="manager_signature">
              <p className="signature">{manager.name}</p>
              <Divider
                sx={{
                  width: "25%",
                  "@media(max-width: 690px)": { width: "80%" },
                }}
              />
              <FormHelperText>
                Manager signed electronically {today.format("DD/MM/YYYY")}
              </FormHelperText>
            </div>
          </div>
          {showAlert ? (
            <Alert severity={severity} sx={{ width: "60%", margin: "2% auto" }}>
              {message}
            </Alert>
          ) : (
            <Button
              variant="contained"
              color="success"
              sx={{ margin: "2% auto" }}
              onClick={handleSubmit}
              disabled={disabled}
            >
              Submit Form
            </Button>
          )}
        </>
      ) : (
        null
        // <Typography variant="h6">Select an employee to see the form</Typography>
      )}
    </main>
  );
}
