import "../staffManagement.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";

export default function Leavers(props) {
  const [notice, setNotice] = useState("");
  const [uniform, setUniform] = useState("");
  const [leavingDate, setLeavingDate] = useState("")
  const [lastWorkedDate, setLastWorkedDate] = useState("")
  const [keysReturned, setKeysReturned] = useState("")
  const [uniformReturned, setUniformReturned] = useState("")
  const [uniformOther, setUniformOther] = useState("N/A")
  const [givenNotice, setGivenNotice] = useState("")
  const [noticeOther, setNoticeOther] = useState("N/A")
  const [noticeWorked, setNoticeWorked] = useState("");
  const [workNotice, setWorkNotice] = useState("")
  const [noticeWorkOther, setNoticeWorkOther] = useState("N/A")
  const [overMonth, setOverMonth] = useState("")
  const [employeePresent, setEmployeePresent] = useState("")

  const update = async (val, type) => {
    var data = {
      leavingDate: leavingDate,
      keysReturned: keysReturned,
      uniformReturned: uniformReturned,
      lastWorkedDate: lastWorkedDate,
      givenNotice: givenNotice,
      overMonth: overMonth,
      employeePresent, employeePresent,
      uniformOther: uniformOther,
      noticeOther: noticeOther,
      workNotice: workNotice,
      noticeWorkOther: noticeWorkOther
    }
    data[type] = val;

    props.updateFormData(data)

    if (type == "employeePresent") {
      if (val == "No") {
        props.hideSignature(true);
      } else {
        props.hideSignature(false);
      }
    }
  }

  return (
    <main className="staff_management">
      <h1>Leavers Form</h1>
      <section className="conversation_form">
        <label>
          Leaving date <span className="label">*</span>
        </label>
        <input type="date" onChange={(e) => { setLeavingDate(e.target.value); update(e.target.value, "leavingDate") }} />
        <FormControl>
          <FormLabel>Confirm return of keys</FormLabel>
          <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setKeysReturned(e.target.value); update(e.target.value, "keysReturned") }}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            <FormControlLabel
              value="Not a key holder"
              control={<Radio />}
              label="Not a key holder"
            />
          </RadioGroup>
        </FormControl>

        <FormControl onChange={(e) => setUniform(e.target.value)}>
          <FormLabel>Confirm return of uniform &amp; name badge</FormLabel>
          <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setUniformReturned(e.target.value); update(e.target.value, "uniformReturned") }}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
            <FormControlLabel value="other" control={<Radio />} label="Other (Detail if they have no uniform issued to them, or if they have returned part of their uniform.)" />
          </RadioGroup>
        </FormControl>
        {uniform === "other" ? <textarea onChange={(e) => { setUniformOther(e.target.value); update(e.target.value, "uniformOther") }} /> : null}
        <label>
          Date of last shift worked <span className="label">*</span>
        </label>
        <input type="date" onChange={(e) => { setLastWorkedDate(e.target.value); update(e.target.value, "lastWorkedDate") }} />

        <FormControl>
          <FormLabel>Has the employee been employed less than 1 Month</FormLabel>
          <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setOverMonth(e.target.value); update(e.target.value, "overMonth") }}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {overMonth == "No" ?
          <>
            <FormControl onChange={(e) => setNotice(e.target.value)}>
              <FormLabel>Has employee given their contractual notice period?</FormLabel>
              <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setGivenNotice(e.target.value); update(e.target.value, "givenNotice") }}>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="other" control={<Radio />} label="Other (Mention here if the employee did not give their full contractual notice)" />
              </RadioGroup>
            </FormControl>
            {notice === "other" ? <textarea onChange={(e) => { setNoticeOther(e.target.value); update(e.target.value, "noticeOther") }} /> : null}
            <FormControl onChange={(e) => setNoticeWorked(e.target.value)}>
              <FormLabel>Did employee work their notice period?</FormLabel>
              <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setWorkNotice(e.target.value); update(e.target.value, "workNotice") }}>
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="other" control={<Radio />} label="Other (Mention if they did not complete their whole notice period)" />
              </RadioGroup>
            </FormControl>
            {noticeWorked === "other" ? <textarea onChange={(e) => { setNoticeWorkOther(e.target.value); update(e.target.value, "noticeWorkOther") }} /> : null}
          </>
          : null}

        <FormControl>
          <FormLabel>Is the employee present when filling this form?</FormLabel>
          <RadioGroup aria-labelledby="Probation" name="Probation" onChange={(e) => { setEmployeePresent(e.target.value); update(e.target.value, "employeePresent") }}>
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>


      </section>
    </main>
  );
}
