//before adding a route: does it need protection?
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HolidayForm from "./components/HolidayForm/HolidayForm";
import Home from "./components/Home";
import InterviewQuestions from "./components/interviews/InterviewQuestions";
import UserPage from "./components/staffPortal/UserPage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Contact from "./components/staffPortal/Contact";
import PassReset from "./components/PassReset";
import NotFound from "./components/animations/notFound/NotFound";
import Handbook from "./components/staffPortal/Handbook";
import Policies from "./components/staffPortal/Policies";
import FirstSigned from "./components/FirstSigned";
import ConversationsHome from "./components/staffManagement/ConversationsHome";
import FormHome from "./components/staffManagement/forms/FormHome";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* NOT LOGGED ROUTES */}
          <Route exact path="/" element={<Home />} />
          <Route path="password-reset" element={<PassReset />} />
          <Route path="interview/:id" element={<InterviewQuestions />} />
          <Route path="login/welcome" element={<FirstSigned />} />
          {/* END OF NOT LOGGED ROUTES */}
          {/* USER ROUTES */}
          <Route
            path=":user"
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="holiday-form"
            element={
              <ProtectedRoute>
                <HolidayForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="contact"
            element={
              <ProtectedRoute>
                <Contact />
              </ProtectedRoute>
            }
          />
          <Route
            path="policies"
            element={
              <ProtectedRoute>
                <Policies />
              </ProtectedRoute>
            }
          />

          {/* END USER ROUTES */}
          {/* STAFF MANAGEMENT ROUTES */}
          <Route
            path="staff-management"
            element={
              <ProtectedRoute>
                <ConversationsHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="staff-management/form/:form"
            element={
              <ProtectedRoute>
                <FormHome />
              </ProtectedRoute>
            }
          />
          {/*END STAFF MANAGEMENT ROUTES */}

          {/* <Route path="*" element={<NotFound />}/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
