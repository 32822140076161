import { useEffect, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import { getEmployeeDetails } from "../../utils/apiUtils";
import "../styles/policies.scss";
import { decryptData } from "../../utils/crypto";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import Button from "@mui/material/Button";
import Loader from "../animations/Loader";
import Alert from "@mui/material/Alert";

export default function Policies() {
  const [employeeId, setEmployeeId] = useState("");
  const [employee, setEmployee] = useState("");
  const [deductions, setDeductions] = useState(false);
  const [cigarrete, setCigarrete] = useState(false);
  const [alcohol, setAlcohol] = useState(false);
  const [environment, setEnvironment] = useState(false);
  const [safety, setSafety] = useState(false);
  const [signature, setSignature] = useState("");
  const [ip, setIp] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [signed, setSigned] = useState(false);
  const [token, setToken] = useState("");
  const config = {
    headers: { "Content-Type": "application/json", "x-auth-token": token },
  };
  //alert
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const currentUser = localStorage.getItem("user");
    const token2 = sessionStorage.getItem("token");
    setToken(token2);
    getEmployeeDetails(currentUser, token2)
      .then((data) => {
        setEmployeeId(data.employeeDetailsId);
        let name = decryptData(data.firstName, process.env.REACT_APP_EMP_KEY);
        let surname = decryptData(data.lastName, process.env.REACT_APP_EMP_KEY);
        setEmployee(`${name} ${surname}`);
        checkSign(data.employeeDetailsId, token2).then((checked) => {
          console.log(checked);
          if (checked == true || checked == null) {
            setSigned(true);
          }
          // If not head office set to Signed
          if (data.site.name != "Head Office") {
            setSigned(true);
          }
        });
      })
      .catch((err) => {
        console.log(`Could not get employee details: ${err}`);
      });
    axios
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIp(res.data.ip);
      })
      .catch((err) => {
        console.log(`Could not get IP Address`);
      });
  }, [reload]);

  const checkSign = async (id, authTok) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/employee/policy/status/${id} `, {
        headers: { "Content-Type": "application/json", "x-auth-token": authTok },
      })
      .then((res) => res.data)
      .catch((err) => {
        console.log(`Could not get data: ${err}`);
      });
  };

  const handleDeductions = () => {
    setDeductions(!deductions);
  };

  const handleCigarretes = () => {
    setCigarrete(!cigarrete);
  };

  const handleAlcohol = () => {
    setAlcohol(!alcohol);
  };

  const handleEnvironment = () => {
    setEnvironment(!environment);
  };

  const handleSafety = () => {
    setSafety(!safety);
  };

  const handleSign = () => {
    setShowLoader(true);
    const signatureData = signature.getTrimmedCanvas().toDataURL("img/png");
    if (!deductions || !cigarrete || !alcohol || !environment || !safety) {
      setSeverity("warning");
      setMessage(`Please agree to all the policies`);
      setShowLoader(false);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else if (signature.isEmpty()) {
      setSeverity("warning");
      setMessage(`Please sign`);
      setShowLoader(false);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      let data = {
        ipAddress: ip,
        signature: signatureData,
        employeeDetailsId: employeeId,
      };
      try {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/employee/policy/update`,
            data,
            config
          )
          .then((res) => {
            setSeverity("success");
            setMessage(`Submitted`);
            setShowLoader(false);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setReload(!reload);
            }, 3000);
          })
          .catch((err) => {
            console.log(`Could not submit: ${err}`);
            setSeverity("error");
            setMessage(`There was an error submitting: ${err}`);
            setShowLoader(false);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          });
      } catch (err) {
        console.log(`Could not submit: ${err}`);
        setSeverity("error");
        setMessage(`There was an error submitting: ${err}`);
        setShowLoader(false);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    }
  };

  return (
    <main>
      <Navigation />
      {!signed ? (
        <>
          <section className="policies_main">
            <h1>Policies</h1>
            <h2>DEDUCTIONS FROM PAY AGREEMENT</h2>
            <h2>{employee}</h2>
            <ul>
              <li>
                If you are overpaid for any reason, the total amount of the
                overpayment will normally be deducted from your next payment but
                if this would cause hardship, arrangements may be made for the
                overpayment to be recovered over a longer period.
              </li>
              <li>
                If you are hourly paid and you "clock in" late you will lose
                pay. If you &ldquo;clock in&rdquo; 5 minutes late you will lose
                5 minutes pay and so on.
              </li>
              <li>
                If you arrive for work more than one hour late without having
                previously notified us, other arrangements may have been made to
                cover your duties and you may be sent off the premises for the
                remainder of the shift without pay.
              </li>
              <li>
                Lateness or absence may result in disciplinary action and/or
                loss of appropriate payment.
              </li>
              <li>
                If there is a temporary shortage of work for any reason, we will
                try to maintain your continuity of employment even if this
                necessitates placing you on short time working, or
                alternatively, lay off. If you are placed on short time working,
                your pay will be reduced according to time actually worked. If
                you are placed on lay off, you will receive no pay other than
                statutory guarantee pay.
              </li>
              <li>
                If you are an allocated key holder, you must ensure that all
                procedures and guidelines are followed when securing the
                building prior to leaving. The keys and any security measure
                such as alarm codes must be kept safe at all times. You must not
                give the keys or alarm code to any third party unless
                authorisation is obtained from a Manager. Any loss or damage
                caused as a result of your failure to follow procedures or your
                negligence in ensuring the safekeeping of the keys and alarm
                code will result in disciplinary action which could lead to your
                summary dismissal. We also reserve the right to deduct the cost
                of any loss, repair or replacement from any monies owing to you
              </li>
              <li>
                Any cash shortages, this includes discrepancies in Paypoint and
                Lotto, at the end of the shift will be the responsibility of the
                shift on duty and must be made good by that shift or stock
                deficiencies that you are found to be responsible for. Any such
                shortages will be deducted from wages.
              </li>
              <li>Any amounts owing to your personal staff account.</li>
              <li>
                Any fuel that you have not called down on your till and/or
                failure to ask a customer if they have any fuel to pay for or
                any fuel card sale that has been incorrectly cashed. Any such
                shortages will be deducted from wages.
              </li>
              <li>
                Any damage to vehicles, stock or property (including
                non-statutory safety equipment) that is the result of your
                carelessness, negligence or deliberate vandalism will render you
                liable to pay the full or part of the cost of repair or
                replacement.
                <ul>
                  <li>
                    Any loss to us that is the result of your failure to observe
                    rules, procedures or instruction, or is as a result of your
                    negligent behaviour or your unsatisfactory standards of work
                    will render you liable to reimburse to us the full or part
                    of the cost of the loss.
                  </li>
                  <li>
                    In the event of an at fault accident whilst driving one of
                    our vehicles you may be required to pay the cost of the
                    insurance excess up to a maximum of &pound;250.00.
                  </li>
                  <li>
                    In the event of failure to pay, such costs will be deducted
                    from your pay.
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                On termination of your employment you must return any Company
                vehicle in your possession to our premises. Failure to return
                the vehicle will result in the cost of its recovery being
                deducted from any monies outstanding to you.
              </li>
              <li>
                Any fines imposed by relevant authorities including (but not
                limited to) speeding and parking will be payable by the
                employee. The Company take no responsibility for the payment of
                fines incurred by the employee during their employment. In the
                event that the Company receive the summons on the
                employee&rsquo;s behalf or owing to a fine incurred by the
                employee, we may pay the fine and deduct the cost from the
                employee&rsquo;s wage.
              </li>
              <li>
                Failure to adequately clean the vehicle may mean you are subject
                to the cost of the valet being deducted from your pay.
              </li>
              <li>
                If you arrive for work and, in our opinion, you are not fit to
                work, we reserve the right to exercise our duty of care if we
                believe that you may not be able to undertake your duties in a
                safe manner or may pose a safety risk to others, and send you
                away for the remainder of the day with or without pay and,
                dependent on the circumstances, you may be liable to
                disciplinary action.
              </li>
              <li>Any goods purchased by you from the Employer.</li>
              <li>
                If you terminate your employment without giving or working the
                required period of notice, as indicated in your individual
                statement of main terms of employment, you will have an amount
                equal to any additional cost of covering your duties during the
                notice period not worked deducted from any termination pay due
                to you. You will also forfeit any contractual accrued holiday
                pay due to you over and above your statutory holiday pay, if you
                fail to give or work the required period of notice.
              </li>
              <li>
                On the termination of your employment you must return all our
                property which is in your possession or for which you have
                responsibility. Failure to return such items will result in the
                cost of the items being deducted from any monies outstanding to
                you.&nbsp;
              </li>
              <li>
                On the termination of your employment you must return all items
                of your uniform to us. Failure to return such items will result
                in the cost of the items being deducted from any monies
                outstanding to you.
              </li>
            </ul>
            <div className="agree__box" onClick={handleDeductions}>
              <input
                type="checkbox"
                id="safety-agree"
                name="Agree"
                checked={deductions}
              />
              <label htmlFor="Agree">
                I have read and agree to the Deductions from Pay policy
              </label>
            </div>
            <h2>CIGARETTE BREAK POLICY</h2>
            <h6>Introduction</h6>
            <p>
              The Company has a strict Cigarette Break Policy in which all
              employees must adhere to at all times. This company policy
              outlines what employees’ responsibilities are when it comes to
              taking cigarette breaks. Therefore, it is important that employees
              understand this policy.
            </p>
            <h6>Scope</h6>
            <p>
              This policy applies to all of our employees who smoke and
              therefore take cigarette breaks at work. The following document
              explains the policy for cigarette breaks which managers need to
              implement with their employees. The purpose of this policy is to
              ensure that Head Office knows where employees are at all times for
              health and safety purposes so that they can manage things more
              closely.
            </p>
            <h6>Policy Elements</h6>
            <p>The following rules always apply: </p>
            <ul>
              <li>
                All employees will fill in a New Starter Form before starting
                working with ourselves where they are asked if they smoke or
                not. This is so that HR are aware of who smokes in each site.
              </li>
              <li>
                All cigarette breaks must take place away from the site in the
                designated smoking areas. All employees will be shown where this
                is as part of their induction process. If you are unsure of
                where this area is if you are working at a different site, then
                ask the manager or another member of staff to show you.
              </li>
              <li>
                Cigarette breaks need to be incorporated into the employees
                break entitlement, and if they go over their entitlement, the
                manager needs to inform HR when submitting the confirmed rotas
                at the end of each week so the necessary deductions can be made.
              </li>
              <li>
                Managers need to provide a list to HR of all the employees in
                their site including themselves who are smokers. This needs to
                be accompanied by a generic one-off rota with a breakdown of
                when they are going to allocate set cigarette breaks for each
                shift. For example, if your site has 2 set shifts for example,
                6am-2pm and 2pm-11pm, you may have the following set cigarette
                breaks: 8am, 10am, 12pm, 4pm, 6pm and 9pm. If on average your
                employees usually take 2/3 smoking breaks per shift, base the
                rota on that. If there are some employees who are excessive
                smokers, the manager needs to arrange this on a case by case
                basis.
              </li>
              <li>
                Before employees take their cigarette breaks, they still need to
                get authorization from their manager at the current time to
                ensure the business needs are covered as customer service comes
                first. If there is not a manager available to ask, then make the
                decision yourself by checking how busy the site is and if there
                is enough cover for you to go on your break at your designated
                time. Remember the customers always come first, so if you are
                due to go on your break but there are customers in the site
                waiting to be served, then wait until they have gone before
                going on your break.
              </li>
              <li>Smoking is prohibited in any company vehicles.</li>
              <li>
                Visitors to the site are prohibited to smoke on the premises,
                and if an employee sees a visitor smoking on the forecourt or in
                the shop, then they should tell them to stop immediately as this
                could be detrimental due to the hazardous materials at the
                sites.
              </li>
              <li>
                Employees should inform their managers of anyone who is not
                complying with this policy.
              </li>
            </ul>
            <h6>Disciplinary Consequences</h6>
            <p>
              When an employee disregards this Policy, the appropriate
              disciplinary action will be taken. In the first instance you will
              be (depending on what element of the policy you have disregarded)
              given a verbal reminder of the Policy. This will be kept on file
              and could be used as further evidence if employees continue to
              repeat this failure to comply. Should there be any recorded repeat
              of this conduct, this may be subject to formal disciplinary
              action. Further consequences including fines may be issued to
              individuals who do not follow this policy by the local councils.
            </p>
            <div className="agree__box" onClick={handleCigarretes}>
              <input
                type="checkbox"
                id="safety-agree"
                name="Agree"
                checked={cigarrete}
              />
              <label htmlFor="cigarrete-agree" >
                I have read and agree to the Cigarette break policy
              </label>
            </div>

            <h2>ALCOHOL POLICY</h2>
            <p>
              Management Policy Statement on employee and contractor alcohol and
              drug abuse.
            </p>
            <ul>
              <li>
                The company is committed to a safe, healthy and productive
                workplace for all employees and contractors.
              </li>
              <li>
                The company recognises that abuse of alcohol, drugs or other
                substance by an employee or contractor will impair that person's
                ability to work properly and safely and could have serious
                consequences for the safety and efficiency of other employees or
                contractors. Such behaviour could also damage the company's
                reputation with the public.
              </li>
              <li>
                The use, possession, distribution or sale of any illegal or
                unprescribed drugs or the misuse of legitimately prescribed
                drugs on company premises is strictly prohibited and is grounds
                for instant dismissal.
              </li>
              <li>
                Possession, consumption, distribution or sale of alcoholic
                beverages, save in the normal course of company trading, is
                strictly prohibited and is grounds for instant dismissal.
              </li>
              <li>
                Being unfit for work because of misuse of drugs or alcohol is
                strictly prohibited and is grounds for instant dismissal.
              </li>
              <li>
                Whilst the policy above refers specifically to alcohol and
                drugs, it applies to all forms of substance abuse.
              </li>
              <li>
                Contractors working on company premises are expected to enforce
                similar policies regarding alcohol and drug abuse.
              </li>
            </ul>
            <p>
              Contractors working on company premises are expected to enforce
              similar policies regarding alcohol and drug abuse.
            </p>
            <div className="agree__box" onClick={handleAlcohol}>
              <input
                type="checkbox"
                id="safety-agree"
                name="Agree"
                checked={alcohol}
              />
              <label htmlFor="Agree">
                I have read and agree to the Alcohol policy
              </label>
            </div>

            <h2>ENVIRONMENTAL POLICY</h2>
            <p>Management Environmental Policy Statement</p>
            <ul>
              <li>
                The company will ensure that all activities meet legal and EC
                Directives/requirements and conditions of any applicable
                Planning Consents.
              </li>
              <li>
                The company will maintain a safe environment for all employees,
                customers and contractors.
              </li>
              <li>
                The company is committed to be a good neighbour and to minimise
                the impact of any company activities on the neighbourhood.
              </li>
              <li>
                Wherever reasonably practicable, the company will use
                environmentally friendly materials, substances and equipment and
                strive to improve the environmental awareness of all staff.
              </li>
              <li>
                In the event of any spills of product, leaks or other incident
                likely to cause any contamination, the company will take all
                steps to contain and dispose of waste products in an appropriate
                manner to facilities equipped and licensed to deal with them.
                All statutory bodies will be notified.
              </li>
            </ul>
            <p>
              All employees/contractors working on company premises are expected
              to confirm to the above policy.
            </p>
            <div className="agree__box" onClick={handleEnvironment}>
              <input
                type="checkbox"
                id="safety-agree"
                name="Agree"
                checked={environment}
              />
              <label htmlFor="Agree">
                I have read and agree to the Environmental policy
              </label>
            </div>

            <h2>SAFETY POLICY</h2>
            <p>
              Management Safety Policy Statement. - Health & Safety at Work etc.
              Act 1974.
            </p>
            <ul>
              <li>
                In addition to complying strictly with health and safety
                measures as required by legislation, it will be the Company's
                policy to promote, and give high priority to, the continuing
                establishment of a healthy and safe working environment.
              </li>
              <li>
                The Company recognises that an effective policy depends on a
                dedicated attitude by all its employees, whether directly
                employed or sub-contracted.
              </li>
              <li>
                The Company's objective is to ensure that for the protection and
                health, both of Its employees and the general public, working
                conditions and practices are established and continuously
                reviewed to achieve a safe, healthy and injury-free operation by
                means of:
              </li>
              <li>A safe and healthy working environment.</li>
              <li>Appropriate training and employee instruction.</li>
              <li>
                Identification of potential hazards to health and safety in the
                workplace and the implementation of plans to eliminate or
                control such hazards.
              </li>
              <li>
                Provision of appropriate and adequate protective clothing.
              </li>
              <li>Provision of specialist advice where appropriate.</li>
              <li>
                Awareness by employees that they themselves have a clear duty to
                take every reasonable precaution to avoid injury to themselves,
                their colleagues and members of the public.
              </li>
              <li>
                The Company will expect that any contractors carrying out work
                on behalf of the Company will have in place adequate health and
                safety procedures and standards to safeguard their own employees
                in addition to the Company's employees and the general public.
                The Company will take reasonable steps to ensure that such
                contractors abide by their procedures.
              </li>
            </ul>
            <p>
              Responsibility for implementing and monitoring this policy lies
              with the management.
            </p>
            <div className="agree__box" onClick={handleSafety}>
              <input
                type="checkbox"
                id="safety-agree"
                name="Agree"
                checked={safety}
              />
              <label htmlFor="Agree">
                I have read and agree to the Safety policy
              </label>
            </div>
          </section>
          <strong style={{ marginTop: "3%" }}>Please sign in the box:</strong>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ className: "sigCanvas" }}
            ref={(ref) => {
              setSignature(ref);
            }}
          />
          <Button
            color="error"
            onClick={() => signature.clear()}
            className="clear_btn"
          >
            Clear signature
          </Button>
          {showLoader ? (
            <Loader />
          ) : showAlert ? (
            <Alert severity={severity}>{message}</Alert>
          ) : (
            <Button variant="contained" color="success" onClick={handleSign}>
              Sign
            </Button>
          )}
        </>
      ) : (
        <section className="policies_main">
          <h1>Policies</h1>
          <h2>{employee}</h2>
          <h3>
            Your policies are signed and up to date, a copy was sent to your
            email
          </h3>
        </section>
      )}
    </main>
  );
}
