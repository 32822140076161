//when adding items add also on mobile menu
import Logo from "./images/logo.png";
import "./styles/nav.scss";
import HomeIcon from "@mui/icons-material/Home";
import CallIcon from "@mui/icons-material/Call";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
//mobile menu
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { getEmployeeDetails } from "../utils/apiUtils";

export default function Navigation() {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [user, setUser] = useState("");
  const [hasStaff, setHasStaff] = useState(false);

  const iconStyle = {
    padding: "0 10px",
    fontSize: "28px",
  };

  useEffect(() => {
    const currentUser = localStorage.getItem("user");
    const token = sessionStorage.getItem("token");
    setUser(currentUser);
    // get position of user for staff management
    try {
      getEmployeeDetails(currentUser, token).then((data) => {
        console.log(data);
        if (data.roleName) {
          if (
            data.roleName.includes("Developer") ||
            data.roleName.toLowerCase().includes("supervisor") ||
            data.roleName.toLowerCase().includes("manager") ||
            data.roleName.toLowerCase().includes("hr")||
            data.adminFormAccess
          ) {
            setHasStaff(true);
          }
        }
      });
    } catch (err) {
      console.log(`Could not get employee details: ${err}`);
    }
  }, []);

  //Mobile Responsiveness
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth < 690) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowWidth]);

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <nav>
      <img src={Logo} alt="RaceTrack Logo" />
      {!isMobile ? (
        <div className="nav__icons">
          <div onClick={() => navigate(`/${user}`)}>
            <HomeIcon sx={iconStyle} />
            <span>Home</span>
          </div>
          <div onClick={() => navigate("/holiday-form")}>
            <FlightTakeoffIcon sx={iconStyle} />
            <span>Holiday Request</span>
          </div>
          <a
            href="https://staff.racetrackpitstop.co.uk/wp-content/uploads/2024/06/Company-Handbook-June-2024.pdf"
            s
            target="_blank"
          >
            <div>
              <AutoStoriesIcon sx={iconStyle} />
              <span>Company Handbook</span>
            </div>
          </a>
          <div onClick={() => navigate("/policies")}>
            <HistoryEduIcon />
            <span>Policies</span>
          </div>
          <div onClick={() => navigate("/contact")}>
            <CallIcon sx={iconStyle} />
            <span>Contact</span>
          </div>
          {hasStaff ? (
            <div onClick={() => navigate("/staff-management")}>
              <PeopleAltIcon sx={iconStyle} />
              <span>Staff Management</span>
            </div>
          ) : null}
          <div onClick={() => logout()}>
            <LogoutIcon sx={iconStyle} />
            <span>Logout</span>
          </div>
        </div>
      ) : (
        <PopupState variant="popover" popupId="nav-popup-menu">
          {(popupState) => (
            <Fragment>
              <MenuOpenIcon {...bindTrigger(popupState)} fontSize="large" />
              <Menu {...bindMenu(popupState)}>
                <MenuItem onClick={() => navigate(`/${user}`)}>
                  <span
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <HomeIcon sx={{ marginRight: "5px" }} />
                    Home
                  </span>
                </MenuItem>
                <MenuItem onClick={() => navigate("/holiday-form")}>
                  <span
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <FlightTakeoffIcon sx={{ marginRight: "5px" }} />
                    Holiday Request
                  </span>
                </MenuItem>
                <a
                  href="https://staff.racetrackpitstop.co.uk/wp-content/uploads/2024/06/Company-Handbook-June-2024.pdf"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#1d1d1d",
                  }}
                >
                  <MenuItem>
                    <span
                      style={{
                        display: "flex",
                        marginBottom: "5px",
                      }}
                    >
                      <AutoStoriesIcon sx={{ marginRight: "5px" }} />
                      Company Handbook
                    </span>
                  </MenuItem>
                </a>
                <MenuItem onClick={() => navigate("/policies")}>
                  <span
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <HistoryEduIcon sx={{ marginRight: "5px" }} />
                    Policies
                  </span>
                </MenuItem>
                <MenuItem onClick={() => navigate("/contact")}>
                  <span
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <CallIcon sx={{ marginRight: "5px" }} />
                    Contact
                  </span>
                </MenuItem>
                {hasStaff ? (
                  <MenuItem onClick={() => navigate("/staff-management")}>
                    <span
                      style={{
                        display: "flex",
                        marginBottom: "5px",
                      }}
                    >
                      <PeopleAltIcon sx={{ marginRight: "5px" }} />
                      Staff Management
                    </span>
                  </MenuItem>
                ) : null}
                <MenuItem onClick={() => logout()}>
                  <span
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                    }}
                  >
                    <LogoutIcon sx={{ marginRight: "5px" }} />
                    Logout
                  </span>
                </MenuItem>
              </Menu>
            </Fragment>
          )}
        </PopupState>
      )}
    </nav>
  );
}
